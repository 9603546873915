import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import Product from '../Product/Product';
import Pagination from '@material-ui/lab/Pagination';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '82%',
      paddingLeft: '24px',
    },
    sectionTitle: {
      marginBottom: theme.spacing(2),
      fontWeight: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& :nth-child(1)': {
        fontWeight: 600,
      },
      '& :nth-child(2)': {
        color: grey[700],
        fontSize: 13,
      },
    },
    pagination: {
      margin: '0 auto',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
      '& .MuiPagination-ul': {
        justifyContent: 'center',
      },
    },
    productContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, calc(20% - 13.3333px))',
      gridAutoRows: 'minmax(calc(152px), max-content)',
      gap: 16,
      width: '100%,',
      minHeight: 500,
    },
  }),
);

const ProductList = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <div className={classes.sectionTitle}>
        <Typography variant="body1" component="p">
          All Products
        </Typography>
        <Typography variant="body1" component="p">
          Menampilkan 1 - 16 barang dari total 200
        </Typography>
      </div>
      <div className={classes.productContainer}>
        {Array.from(Array(20).keys()).map((value) => (
          <Product key={value} data={'product'} />
        ))}
      </div>
      <Pagination count={10} className={classes.pagination} variant="outlined" shape="rounded" />
    </div>
  );
};

export default ProductList;
