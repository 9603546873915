import * as React from 'react';
import ProductList from '../components/ProductList/ProductList';

const Landing = () => {
  return (
    <>
      <ProductList />
    </>
  );
};

export default Landing;
