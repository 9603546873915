import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { login } from '../store/auth/actions';
import { ILogin } from '../store/auth/types';
import BackgroundLogin from '../assets/images/bg-login.png';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Mail, ChevronLeft } from 'react-feather';
import ButtonRounded from '../components/Button/ButtonRounded';
import InputRounded from '../components/Form/InputRounded';
import { Link } from 'react-router-dom';
import { initialLoginValue, LoginProps, LoginError, initialLoginError } from '../interface/LoginInterface';
import LogoOxone from '../assets/images/logo-oxone.svg';

const useLoginStyle = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      backgroundImage: `url(${BackgroundLogin})`,
      backgroundSize: 'cover',
      height: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    form: {
      width: 350,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    inputIcon: {
      marginRight: theme.spacing(2),
    },
    link: {
      marginTop: theme.spacing(6),
      textDecoration: 'none',
      color: 'rgba(255,255,255,.5)',
      fontSize: 13,
    },
    copyrights: {
      fontSize: 12,
      color: 'rgba(255,255,255,.5)',
      width: 200,
      marginTop: theme.spacing(6),
      textAlign: 'center',
      lineHeight: 2,
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 120,
        marginBottom: theme.spacing(2),
      },
      '& .MuiTypography-root': {
        color: '#fff',
        marginBottom: theme.spacing(8),
      },
    },
    linkBack: {
      marginBottom: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      textDecoration: 'none',
      '&:focus, &:hover': {
        textDecoration: 'none',
      },
    },
  }),
);

interface DispatchProps {
  login: (data: ILogin) => void;
}

type Props = DispatchProps;

const ForgotPassword: React.FC<Props> = ({}: Props) => {
  const classes = useLoginStyle();

  const [loginForm, setLoginForm] = React.useState<LoginProps>(initialLoginValue);
  const [error, setError] = React.useState<LoginError>(initialLoginError);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginForm({ ...loginForm, [e.target.name]: { ...[e.target.name], value: e.target.value } });
  };

  const submitSignIn = () => {
    if (!loginForm.email.isValid) {
      setError({ ...error, email: 'Email not correct' });
      console.info(error, loginForm);
    }
    if (!loginForm.password.isValid) {
      setError({ ...error, password: 'Password not correct' });
      console.info(error, loginForm);
    }
    alert('submitted');
  };

  return (
    <div className={classes.background}>
      <div className={classes.form}>
        <div className={classes.logoContainer}>
          <img src={LogoOxone} />
          <Typography variant="subtitle2" component="p">
            Be a chef at your own home
          </Typography>
        </div>

        <Link to="/log-in" className={classes.linkBack}>
          <ChevronLeft size={20} />
          <Typography variant="subtitle1" component="p">
            Forgot Password
          </Typography>
        </Link>

        <InputRounded
          name="email"
          value={loginForm.email.value}
          startAdornment={<Mail className={classes.inputIcon} width={20} />}
          placeholder={'Email'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeValue(e);
          }}
        />
        <ButtonRounded onClick={submitSignIn}>Send Recovery Password</ButtonRounded>
        <div className={classes.copyrights}>Copyright © 2021 Oxone Indonesia All Rights Reserved</div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (data: ILogin) => dispatch(login(data)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
