import * as React from 'react';
import { makeStyles, Theme, createStyles, Paper, Typography, Box, Divider, Button } from '@material-ui/core';
import { ChevronRight, Info } from 'react-feather';
import NumberFormat from 'react-number-format';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    actionGroup: {
      padding: theme.spacing(2),
      borderRadius: 10,
      position: 'sticky',
      top: 90,
    },
    summary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    buttonLocation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 10,
      height: 45,
      width: '100%',
      marginTop: 2,
    },
    buttonOrder: {
      textAlign: 'center',
      borderRadius: 10,
      height: 45,
      width: '100%',
    },
  }),
);

interface OrderSummaryProps {
  totalQty: number;
  totalPrice: number;
  handleLocationClick: () => void;
  handleOrderClick: () => void;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ totalQty, totalPrice, handleLocationClick, handleOrderClick }: OrderSummaryProps) => {
  const classes = useStyle();

  return (
    <Box>
      <Paper variant="outlined" className={classes.actionGroup}>
        <Box fontWeight="fontWeightMedium" fontSize="1rem">
          Order Summary
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="body2">Total item</Typography>
          <Box fontWeight="fontWeightBold" fontSize={16}>
            {totalQty}
          </Box>
        </Box>
        <Box my={1} color={grey[600]} fontSize={12}>
          Lokasi pengiriman
        </Box>
        <Button variant="outlined" color="primary" disableElevation onClick={handleLocationClick} className={classes.buttonLocation}>
          <Box component="span">Pilih Lokasi</Box>
          <ChevronRight size={20} />
        </Button>
        <Box my={2}>
          <Divider />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="body2">Total price</Typography>
          <NumberFormat
            value={totalPrice}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'Rp'}
            renderText={(value) => (
              <Box fontWeight="fontWeightBold" fontSize="1rem">
                {value}
              </Box>
            )}
          />
        </Box>
        <Button variant="contained" color="primary" disableElevation className={classes.buttonOrder} onClick={handleOrderClick}>
          Order Now
        </Button>
        <Box display="flex" alignItems="center" mt={2}>
          <Info size={24} color={grey[700]} />
          <Box component="span" ml={1} fontSize={12} color={grey[500]} lineHeight={1.4}>
            Periksa kembali produk dan quantity sebelum melakukan order
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default OrderSummary;
