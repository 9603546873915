import { Card, CardContent, CardMedia, makeStyles, Typography, Theme, createStyles, CardActionArea } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SampleProduct from '../../assets/images/sample-product.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
      border: '1px solid #EAEBEC',
      borderRadius: 10,
    },
    actionArea: {
      padding: 0,
    },
    content: {
      padding: theme.spacing(2),
    },
    category: {
      color: grey[400],
      marginBottom: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(1),
      lineHeight: 1.4,
      fontSize: 14,
    },
    price: {
      fontWeight: 700,
    },
    media: {
      height: 220,
    },
  }),
);

interface StateProps {
  data: string;
}

const Product: React.FC<StateProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  function handleClick(title: string) {
    history.push('/catalog/' + title.split(' ').join('-'), { from: 'catalog page' });
  }

  console.info('loca', location);

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea className={classes.actionArea} onClick={() => handleClick('Aluminum Pressure Cooker 20L')}>
        <CardMedia className={classes.media} image={SampleProduct} title="Contemplative Reptile" />
        <CardContent className={classes.content}>
          <Typography variant="body2" className={classes.category} component="p">
            Cooking
          </Typography>
          <Typography variant="subtitle1" className={classes.title} component="p">
            Aluminum Pressure Cooker 20L
          </Typography>
          <Typography variant="subtitle1" className={classes.price} component="p">
            Rp 500.000
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Product;
