import { put, takeLatest } from 'redux-saga/effects';
import { login } from './actions';
import { AuthTypes } from './types';

export function* checkUserAuthentication() {
  try {
    const auth = yield window.localStorage.getItem('auth');
    const formatted = typeof auth === 'string' ? JSON.parse(auth) : null;
    formatted ? yield put({ type: AuthTypes.AUTHENTICATE }) : yield put({ type: AuthTypes.UNAUTHENTICATE });
  } catch (error) {
    console.info('error check auth');
  }
}

export function* loginRequest({ payload }: ReturnType<typeof login>) {
  try {
    yield window.localStorage.setItem('user', JSON.stringify({ email: payload.email, password: payload.password }));
    yield window.localStorage.setItem('auth', JSON.stringify(true));
    yield put({ type: AuthTypes.AUTHENTICATE });
    yield put({ type: AuthTypes.AUTH_LOGIN_SUCCESS, payload: { email: payload.email, password: payload.password } });
  } catch (error) {
    yield put({ type: AuthTypes.AUTH_LOGIN_FAILED });
    throw new Error('Error exist in fetchTopic function');
  }
}

function* authWatcher() {
  yield takeLatest(AuthTypes.CHECK_AUTHENTICATE, checkUserAuthentication);
  yield takeLatest(AuthTypes.AUTH_LOGIN, loginRequest);
}

export default authWatcher;
