import * as React from 'react';
import { grey } from '@material-ui/core/colors';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Calendar, Search } from 'react-feather';
// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    container: {
      width: '100%',
    },
    root: {
      margin: '0 auto',
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 980,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
      marginBottom: theme.spacing(16),
      '& .MuiPaper-root': {
        borderRadius: 10,
      },
    },
    tabContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      alignItems: 'center',
      columnGap: 12,
      padding: theme.spacing(1),
      borderRadius: 15,
      border: `1px solid ${grey[200]}`,
      marginBottom: theme.spacing(2),
      backgroundColor: grey[100],
      '& .MuiButtonBase-root': {
        height: 45,
        borderRadius: 10,
        textTransform: 'capitalize',
      },
    },
    pagination: {
      margin: theme.spacing(4),
      '& .MuiPagination-ul': {
        justifyContent: 'flex-end',
      },
    },
    filterContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1.5fr',
      columnGap: 24,
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  }),
);

const FilterTable = () => {
  const classes = useStyles();

  return (
    <div className={classes.filterContainer}>
      <TextField label="Filter Branch" placeholder="Choose branch" />
      <TextField
        label="Start Date"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Calendar size={20} color={grey[400]} />
            </InputAdornment>
          ),
        }}
        defaultValue="01/02/2021"
      />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Calendar size={20} color={grey[400]} />
            </InputAdornment>
          ),
        }}
        label="End Date"
        defaultValue="01/02/2021"
      />
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search size={20} color={grey[400]} />
            </InputAdornment>
          ),
        }}
        label="Search"
        placeholder="Search by Order ID & Location"
      />
    </div>
  );
};

interface OrderTabProps {
  handleClick: (index: number) => void;
  activeTab: number;
}

const OrderTab: React.FC<OrderTabProps> = ({ handleClick, activeTab }: OrderTabProps) => {
  const classes = useStyles();

  return (
    <div className={classes.tabContainer}>
      <Button variant={activeTab === 0 ? 'contained' : 'text'} color="primary" onClick={() => handleClick(0)}>
        Order List
      </Button>
      <Button variant={activeTab === 1 ? 'contained' : 'text'} color="primary" onClick={() => handleClick(1)}>
        Retur List
      </Button>
      <Button variant={activeTab === 2 ? 'contained' : 'text'} color="primary" onClick={() => handleClick(2)}>
        Replacement List
      </Button>
    </div>
  );
};

function createData(orderID: string, soID: string, orderDate: string, location: string, orderStatus: string, qty: string) {
  return { orderID, soID, orderDate, location, orderStatus, qty };
}

const rows = [
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
  createData('O-210116001', 'SO180900635', '20-01-2021 08:56:56', 'Cabang Nusa Indah 1', 'Partial', '815/715'),
];
const OrderList = () => {
  const classes = useStyles();
  // const history = useHistory();
  const [activeTab, setActiveTab] = React.useState<number>(0);

  // function handleClick(id: string) {
  //   history.push('/order-detail/' + id, { from: 'catalog page' });
  // }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <OrderTab activeTab={activeTab} handleClick={(index: number) => setActiveTab(index)} />
        <FilterTable />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>SO ID</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Lokasi</TableCell>
                <TableCell>Order Status</TableCell>
                <TableCell>Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.orderID}>
                  <TableCell>{row.orderID}</TableCell>
                  <TableCell>{row.soID}</TableCell>
                  <TableCell>{row.orderDate}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{row.orderStatus}</TableCell>
                  <TableCell>{row.qty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination count={10} className={classes.pagination} variant="outlined" shape="rounded" />
        </TableContainer>
      </div>
    </div>
  );
};

export default OrderList;
