export async function getHeaders(accessToken: string): Promise<any> {
  // const timezone = new Date().getTimezoneOffset();
  // const clientTZ = (timezone * -1) / 60;
  // const client_tz =
  //   clientTZ >= 0 ? 'GMT+' + String(clientTZ) : 'GMT' + String(clientTZ);
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + accessToken,
  };
}

export async function getHeadersMultiPart(accessToken: string): Promise<any> {
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + accessToken,
  };
}
