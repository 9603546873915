import * as React from 'react';
import { makeStyles, Theme, createStyles, Typography, Checkbox, Button, Box } from '@material-ui/core';
import ProductImage from '../../assets/images/sample-product-1.png';
import { grey } from '@material-ui/core/colors';
import InputQuantity from '../Form/InputQuantity';
import { Trash2 } from 'react-feather';
import NumberFormat from 'react-number-format';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'minmax(40px, 50px) minmax(250px, 1fr) minmax(80px, 250px)',
      columnGap: 16,
      padding: theme.spacing(2),
      alignItems: 'center',
      borderRadius: 10,
      '& img': {
        height: 80,
        width: 80,
        borderRadius: 8,
        border: `1px solid ${grey[300]}`,
        marginRight: theme.spacing(2),
      },
    },
    actionItemWrapper: {
      display: 'grid',
      gridTemplateColumns: '35px 1fr 24px',
      columnGap: 10,
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
    },
    checkbox: {
      height: 24,
      width: 24,
    },
    button: {
      minWidth: 35,
      minHeight: 35,
      border: '1px solid #B4B5B8',
      borderRadius: 10,
      padding: 0,
      backgroundColor: '#fff',
    },
  }),
);

interface StateProps {
  index: number;
}

type Props = StateProps;

const CartItem: React.FC<Props> = (props: Props) => {
  const classes = useStyle();
  const [itemSelected, setItemSelected] = React.useState<boolean>(false);
  const [qty, setQty] = React.useState<number>(1);

  return (
    <Box className={classes.root} style={{ backgroundColor: props.index % 2 !== 0 ? grey[100] : '#fff' }}>
      <Checkbox
        checked={itemSelected}
        onChange={() => setItemSelected(!itemSelected)}
        name="checkAll"
        color="primary"
        className={classes.checkbox}
      />
      <Box alignItems="center" display="flex">
        <img src={ProductImage} />
        <Box>
          <Box color={grey[400]} fontSize={14} mb={1}>
            Cooking
          </Box>
          <Box mb={2} fontSize={14}>
            Alumunium coil
          </Box>
          <NumberFormat
            value={2000000}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'Rp'}
            renderText={(value) => (
              <Box fontWeight="fontWeightBold" fontSize={16}>
                {value}
              </Box>
            )}
          />
        </Box>
      </Box>
      <Box className={classes.actionItemWrapper}>
        <Button variant="outlined" color="primary" className={classes.button}>
          <Trash2 size={20} />
        </Button>
        <InputQuantity qty={qty} handleQtyChange={(qty: number) => setQty(qty)} />
        <Typography variant="body1">pcs</Typography>
      </Box>
    </Box>
  );
};

export default CartItem;
