import { ILogin } from '../../../types/authTypes';
import { AuthTypes } from './types';

export function checkLogin() {
  return {
    type: AuthTypes.CHECK_AUTHENTICATE,
  };
}

export function login(data: ILogin) {
  return {
    type: AuthTypes.AUTH_LOGIN,
    payload: data,
  };
}
