import * as React from 'react';
import { makeStyles, createStyles, Theme, Button, TextField } from '@material-ui/core';
import { Minus, Plus } from 'react-feather';
import NumberFormat from 'react-number-format';
import { InputQuantityProps, NumberFormatCustomProps } from '../../interface/InputQuantity.interface';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    quantity: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiButton-outlined': {
        padding: 0,
      },
    },
    button: {
      minWidth: 35,
      minHeight: 35,
      border: '1px solid #B4B5B8',
      borderRadius: 10,
      backgroundColor: '#fff',
    },
    input: {
      height: 35,
      margin: `0 ${theme.spacing(2)}px`,
      '& .MuiInput-input': {
        paddingBottom: 10,
        textAlign: 'center',
        fontSize: 16,
      },
    },
  }),
);

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

const InputQuantity: React.FC<InputQuantityProps> = ({ qty, handleQtyChange }: InputQuantityProps) => {
  const classes = useStyle();

  function onInputQty(e: React.ChangeEvent<HTMLInputElement>) {
    handleQtyChange(Number(e.target.value));
  }

  function incrementQty() {
    handleQtyChange(qty + 1);
  }

  function decrementQty() {
    handleQtyChange(qty - 1);
  }

  return (
    <div className={classes.quantity}>
      <Button disabled={qty <= 0} variant="outlined" color="primary" className={classes.button} onClick={decrementQty}>
        <Minus size={20} />
      </Button>
      <TextField
        value={qty}
        className={classes.input}
        onChange={onInputQty}
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
      />
      <Button variant="outlined" color="primary" className={classes.button} onClick={incrementQty}>
        <Plus size={20} />
      </Button>
    </div>
  );
};

export default InputQuantity;
