import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import * as React from 'react';
import SampleProduct from '../../assets/images/sample-product.png';
import SampleProduct1 from '../../assets/images/sample-product-1.png';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    mainImage: {
      borderRadius: 10,
      border: `thin solid ${grey[300]}`,
      [theme.breakpoints.up('lg')]: {
        width: 348,
        height: 348,
      },
      [theme.breakpoints.down('lg')]: {
        width: 300,
        height: 300,
      },
    },
    smallImageContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: 10,
      width: '100%,',
      height: '100%',
      minHeight: 64,
      marginTop: theme.spacing(2),
    },
    smallImage: {
      width: '100%',
      borderRadius: 10,
      border: `1px solid ${grey[300]}`,
      cursor: 'pointer',
    },
    smallImageSelected: {
      width: '100%',
      borderRadius: 10,
      border: '2px solid #000',
      cursor: 'pointer',
    },
    imageMagnifier: {
      backgroundSize: 800,
      backgroundRepeat: 'no-repeat',
      transform: 'scale(1)',
      borderRadius: 10,
      border: `thin solid ${grey[300]}`,
      [theme.breakpoints.up('lg')]: {
        width: 348,
        height: 348,
      },
      [theme.breakpoints.down('lg')]: {
        width: 300,
        height: 300,
      },
    },
  }),
);

const ImageProduct = () => {
  const imageZoom = React.useRef<any>(null);
  const classes = useStyle();
  const [selectedImage, setSelectedImage] = React.useState<any>(0);
  const [previewImage, setPreviewImage] = React.useState<any>(null);
  const [imageMagnifier, setImageMagnifier] = React.useState<boolean>(false);
  const images = [
    { id: 0, image: SampleProduct1 },
    { id: 1, image: SampleProduct },
    { id: 2, image: SampleProduct1 },
    { id: 3, image: SampleProduct },
  ];

  React.useEffect(() => {
    selectImagePreview(0);
  }, []);

  function handleWheel(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // const boundLeft = imageZoom.current.getBoundingClientRect().left;
    const x = -e.nativeEvent.offsetX;
    const y = -e.nativeEvent.offsetY;
    imageZoom.current.style.backgroundPositionX = x + 'px';
    imageZoom.current.style.backgroundPositionY = y + 'px';
    imageZoom.current.style.cursor = 'crosshair';
  }

  function selectImagePreview(id: number) {
    setSelectedImage(id);
    const image: any = images.find((image: any) => image.id === id);
    setPreviewImage(image.image);
  }

  return (
    <Box className={classes.root}>
      <Box position="sticky" top={90}>
        {!imageMagnifier && (
          <img
            className={classes.mainImage}
            src={previewImage}
            onMouseEnter={() => setImageMagnifier(true)}
            onMouseLeave={() => setImageMagnifier(false)}
          />
        )}
        {imageMagnifier && (
          <div
            style={{ backgroundImage: `url('${previewImage}')` }}
            ref={imageZoom}
            className={classes.imageMagnifier}
            onMouseLeave={() => setImageMagnifier(false)}
            onMouseMove={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleWheel(e)}
          />
        )}
        <Box className={classes.smallImageContainer}>
          {images.map((value: any) => (
            <img
              onMouseEnter={() => selectImagePreview(value.id)}
              src={value.image}
              key={value.id}
              className={value.id === selectedImage ? classes.smallImageSelected : classes.smallImage}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ImageProduct;
