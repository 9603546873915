import { withStyles, InputBase, Theme, createStyles } from '@material-ui/core';

const InputRounded = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
      height: 50,
      borderRadius: 100,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      alignItems: 'center',
      backgroundColor: theme.palette.primary.contrastText,
      width: '100%',
      transition: theme.transitions.create(['border']),
    },
    input: {
      height: '100%',
      // '&:focus': {
      //   color: 'green',
      // },
    },
    label: {
      textTransform: 'capitalize',
    },
  }),
)(InputBase);

export default InputRounded;
