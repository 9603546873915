import * as React from 'react';
import { makeStyles, Theme, createStyles, Box, CardContent, Card, Typography, List, ListItem } from '@material-ui/core';
import logoOxone from '../assets/images/logo-oxone-black.svg';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    root: { padding: theme.spacing(5) },
    topBorderContainer: {
      backgroundColor: theme.palette.primary.main,
      height: 20,
    },
    logo: {
      width: 150,
    },
    containerHeader: {
      padding: theme.spacing(2),
    },
  }),
);

const TermAndCondition = () => {
  const classes = useStyle();

  function handleClick() {
    console.info('handle');
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Typography variant="h1">Syarat & Ketentuan</Typography>

        <Typography variant="body1">
          Selamat datang di aplikasi Oxone online Syarat & ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan
          oleh PT. Octa Utama terkait penggunaan aplikasi Oxone online. Pengguna disarankan membaca dengan seksama karena dapat berdampak
          kepada hak dan kewajiban Pengguna di bawah hukum. Dengan mendaftar dan/atau menggunakan aplikasi Oxone online, maka pengguna
          dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat & ketentuan. Syarat & ketentuan ini merupakan
          bentuk kesepakatan yang dituangkan dalam sebuah perjanjian yang sah antara Pengguna dengan PT. Octa Utama. Jika pengguna tidak
          menyetujui salah satu, sebagian, atau seluruh isi Syarat & ketentuan, maka pengguna tidak diperkenankan menggunakan layanan di
          aplikasi Oxone online.
        </Typography>

        <Typography variant="h2">A. Definisi</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              PT Octa Utama adalah suatu perseroan terbatas yang menjalankan kegiatan usaha jasa web portal & aplikasi Selanjutnya disebut
              Oxone
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Aplikasi Oxone terdapat di Application Store di semua perangkat telepon genggam.</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Syarat & ketentuan adalah perjanjian antara Pengguna dan Oxone yang berisikan seperangkat peraturan yang mengatur hak,
              kewajiban, tanggung jawab pengguna dan Oxone, serta tata cara penggunaan sistem layanan Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna adalah pihak yang menggunakan layanan Oxone, termasuk namun tidak terbatas pada pembeli, maupun pihak lain yang
              sekedar berkunjung ke Aplikasi Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembeli adalah Pengguna terdaftar yang melakukan permintaan atas Barang yang dijual oleh Oxone online di Aplikasi Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Barang adalah benda yang berwujud / memiliki fisik Barang yang dapat diantar / memenuhi kriteria pengiriman oleh perusahaan
              jasa pengiriman Barang.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Feed adalah fitur pada Aplikasi Oxone yang menampilkan konten dari Oxone online, KOL, atau pihak lainnya terkait produk Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Key Opinion Leaders atau KOL adalah pihak yang mempromosikan Barang atau Penjual tertentu melalui Feed.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Rekening Resmi Oxone adalah rekening bersama yang disepakati oleh Oxone dan para pengguna untuk proses transaksi di Aplikasi
              Oxone.
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">B. Akun, Refund, Password dan Keamanan</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Pengguna dengan ini menyatakan bahwa pengguna adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah
              perjanjian yang sah menurut hukum.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Oxone tidak memungut biaya pendaftaran kepada Pengguna.</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna yang telah mendaftar berhak bertindak sebagai: - Pembeli - Penonton, dengan memanfaatkan layanan Video/Foto.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Oxone tanpa pemberitahuan terlebih dahulu kepada Pengguna, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap
              dugaan pelanggaran atau pelanggaran Syarat & ketentuan dan/atau hukum yang berlaku.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Oxone memiliki kewenangan untuk akun Pengguna baik sementara maupun permanen apabila didapati adanya tindakan kecurangan dalam
              bertransaksi dan/atau pelanggaran terhadap syarat dan ketentuan Oxone. Pengguna menyetujui bahwa Oxone berhak melakukan
              tindakan lain yang diperlukan terkait hal tersebut.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna dilarang untuk menciptakan dan/atau menggunakan perangkat, software, fitur dan/atau alat lainnya yang bertujuan untuk
              melakukan manipulasi pada sistem Oxone, termasuk namun tidak terbatas pada : (i) manipulasi data Aplikasi; (ii) kegiatan
              perambanan (crawling/scraping); (iii) kegiatan otomatisasi dalam transaksi, promosi, dsb; (v) penambahan produk ke etalase;
              dan/atau (vi) aktivitas lain yang secara wajar dapat dinilai sebagai tindakan manipulasi sistem.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna memiliki hak untuk melakukan perubahan pada nama akun sebanyak jumlah kesempatan yang disediakan dan Oxone berhak
              merubah jumlah kesempatan perubahan pada nama akun. Pengguna harus memastikan bahwa perubahan pada nama akun telah sesuai
              dengan yang diinginkan dan bertanggung jawab secara pribadi atas perubahan nama akun yang dilakukan oleh Pengguna. Pengguna
              menyetujui bahwa setiap perikatan yang terjadi sebelum perubahan nama tetap mengikat Pengguna.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam
              akun Pengguna.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Oxone tidak akan meminta username, password maupun kode SMS verifikasi atau kode OTP milik akun Pengguna untuk alasan apapun,
              oleh karena itu Oxone menghimbau Pengguna agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang
              mengatasnamakan Oxone atau pihak lain yang tidak dapat dijamin keamanannya.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna setuju untuk memastikan bahwa Pengguna keluar dari akun di akhir setiap sesi dan memberitahu Oxone jika ada
              penggunaan tanpa izin atas sandi atau akun Pengguna.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna dengan ini menyatakan bahwa Oxone tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas
              penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada meminjamkan atau
              memberikan akses akun kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, memberikan atau
              memperlihatkan password atau email kepada pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan kerugian ataupun
              kendala pada akun Pengguna.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna berhak melakukan Refund pada produk Oxone online yang bersifat Electrical (membutuhkan tegangan listrik) selama 2
              minggu/14 hari setelah barang di terima dengan kondisi barang sebagai berikut; Tidak berfungsi, pecah saat pengiriman, ketidak
              lengkapan barang.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna miliki hak untuk melakukan klaim garansi produk yang bersifat Electrical di service center Oxone
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna dapat diberikan Refund dalam bentuk yang sesuai dengan pemilihan pembayaran pengguna sebelum nya
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">C. Transaksi Pembelian</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Pembeli wajib bertransaksi melalui prosedur transaksi yang telah ditetapkan oleh Oxone. Pembeli melakukan pembayaran dengan
              menggunakan metode pembayaran yang sebelumnya telah dipilih oleh Pembeli.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Saat melakukan pembelian Barang, Pembeli menyetujui bahwa:</Typography>
          </li>
          <ol type="a">
            <li>
              <Typography variant="body1">
                Pembeli bertanggung jawab untuk membaca, memahami, dan menyetujui informasi/deskripsi keseluruhan Barang (termasuk
                didalamnya namun tidak terbatas pada warna, kualitas, fungsi, dan lainnya) sebelum membuat tawaran atau komitmen untuk
                membeli.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Pembeli mengakui bahwa warna sebenarnya dari produk sebagaimana terlihat di Aplikasi Oxone tergantung pada monitor komputer
                Pembeli. Oxone telah melakukan upaya terbaik untuk memastikan warna dalam foto-foto yang ditampilkan di Aplikasi Oxone
                muncul seakurat mungkin, tetapi tidak dapat menjamin bahwa penampilan warna pada Aplikasi Oxone akan akurat.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Pengguna masuk ke dalam kontrak yang mengikat secara hukum untuk membeli Barang ketika Pengguna membeli suatu barang.
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1">
              Oxone memiliki kewenangan sepenuhnya untuk menolak pembayaran tanpa pemberitahuan terlebih dahulu.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembayaran oleh Pembeli wajib dilakukan segera (selambat-lambatnya dalam batas waktu 2 hari) setelah Pembeli melakukan
              check-out. Jika dalam batas waktu tersebut pembayaran atau konfirmasi pembayaran belum dilakukan oleh pembeli, Oxone memiliki
              kewenangan untuk membatalkan transaksi dimaksud. Pengguna tidak berhak mengajukan klaim atau tuntutan atas pembatalan
              transaksi tersebut.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembeli menyetujui untuk tidak memberitahukan atau menyerahkan bukti pembayaran dan/atau data pembayaran kepada pihak lain
              selain Oxone. Dalam hal terjadi kerugian akibat pemberitahuan atau penyerahan bukti pembayaran dan/atau data pembayaran oleh
              Pembeli kepada pihak lain, maka hal tersebut akan menjadi tanggung jawab Pembeli.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembeli memahami dan menyetujui bahwa setiap masalah pengiriman Barang yang disebabkan keterlambatan pembayaran adalah
              merupakan tanggung jawab dari Pembeli.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembeli memahami dan menyetujui bahwa masalah keterlambatan proses pembayaran dan biaya tambahan yang disebabkan oleh
              perbedaan bank yang Pembeli pergunakan dengan bank Rekening resmi Oxone adalah tanggung jawab Pembeli secara pribadi.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengembalian dana dari Oxone kepada Pembeli hanya dapat dilakukan jika dalam keadaan-keadaan tertentu berikut ini:
            </Typography>
          </li>
          <ol type="a">
            <li>
              <Typography variant="body1">Kelebihan pembayaran dari Pembeli atas harga Barang,</Typography>
            </li>
            <li>
              <Typography variant="body1">
                Masalah pengiriman Barang telah teridentifikasi secara jelas dari Penjual yang mengakibatkan pesanan Barang tidak sampai,
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Penjual tidak bisa menyanggupi order karena kehabisan stok, perubahan ongkos kirim, maupun penyebab lainnya,
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Penyelesaian permasalahan melalui Support berupa keputusan untuk pengembalian dana kepada Pembeli atau hasil keputusan dari
                pihak Oxone.
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1">
              Pembeli wajib melakukan pembayaran dengan nominal yang sesuai dengan jumlah tagihan beserta kode unik (apabila ada) yang
              tertera pada halaman pembayaran. PT Octa Utama tidak bertanggungjawab atas kerugian yang dialami Pembeli apabila melakukan
              pembayaran yang tidak sesuai dengan jumlah tagihan yang tertera pada halaman pembayaran.
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">D. Harga</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Harga Barang yang terdapat dalam Aplikasi Oxone adalah harga yang ditetapkan oleh PT. Octa Utama
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pembeli memahami dan menyetujui bahwa kesalahan keterangan harga dan informasi lainnya yang disebabkan tidak terbaharuinya
              halaman Aplikasi Oxone dikarenakan browser/ISP yang dipakai Pembeli adalah tanggung jawab Pembeli.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Dengan melakukan pemesanan melalui Oxone, Pengguna menyetujui untuk membayar total biaya yang harus dibayarkan sebagaimana
              tertera dalam halaman pembayaran, yang terdiri dari harga barang, ongkos kirim, dan biaya-biaya lain yang mungkin timbul dan
              akan diuraikan secara tegas dalam halaman pembayaran. Pengguna setuju untuk melakukan pembayaran melalui metode pembayaran
              yang telah dipilih sebelumnya oleh Pengguna.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Aplikasi Oxone untuk saat ini hanya melayani transaksi Barang dalam mata uang Rupiah.</Typography>
          </li>
        </ol>

        <Typography variant="h2">E. Tarif Pengiriman</Typography>

        <Typography variant="body1">
          Pembeli memahami dan mengerti bahwa Oxone telah melakukan usaha sebaik mungkin dalam memberikan informasi tarif pengiriman kepada
          Pembeli berdasarkan lokasi secara akurat, namun Oxone tidak dapat menjamin keakuratan data tersebut dengan yang ada pada cabang
          setempat. Karena itu Oxone menyarankan kepada Penjual untuk mencatat terlebih dahulu tarif yang diberikan Oxone, agar dapat
          dibandingkan dengan tarif yang dibebankan di cabang setempat. Apabila mendapati perbedaan, mohon sekiranya untuk menginformasikan
          kepada kami melalui menu contact us dengan memberikan data harga yang didapat beserta kota asal dan tujuan, agar dapat kami
          telusuri lebih lanjut. Pengguna memahami dan menyetujui bahwa selisih biaya pengiriman Barang adalah di luar tanggung jawab Oxone,
          dan oleh karena itu, adalah kebijakan Penjual sendiri untuk membatalkan atau tetap melakukan pengiriman Barang.
        </Typography>

        <Typography variant="h2">F. Kartu Kredit</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Pengguna dapat memilih untuk mempergunakan pilihan metode pembayaran menggunakan kartu kredit untuk transaksi pembelian Barang
              dan produk digital melalui Aplikasi Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Transaksi pembelian barang dengan menggunakan kartu kredit dapat dilakukan untuk transaksi pembelian dengan nilai total
              belanja minimal Rp. 50.000 (lima puluh ribu rupiah)
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Transaksi pembelian barang dengan menggunakan kartu kredit wajib mengikuti syarat dan ketentuan yang diatur oleh Oxone dan
              mempergunakan kurir/logistik yang disediakan dan terhubung dengan Aplikasi Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna dilarang untuk mempergunakan metode pembayaran kartu kredit di luar peruntukan sebagai alat pembayaran.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Apabila terdapat transaksi pembelian barang dengan menggunakan kartu kredit yang melanggar ketentuan hukum dan/atau syarat
              ketentuan Oxone, maka Oxone berwenang untuk:
            </Typography>
          </li>

          <ol type="a">
            <li>
              <Typography variant="body1">
                menahan dana transaksi selama diperlukan oleh Oxone, pihak Bank, maupun mitra payment gateway terkait untuk melakukan
                investigasi yang diperlukan, sekurang-kurangnya 14 (empat belas) hari;
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                melakukan pemotongan dana sebesar 15% (lima belas persen) dari nilai transaksi, serta menarik kembali nilai subsidi
                sehubungan penggunaan kartu kredit.
              </Typography>
            </li>
          </ol>
          <li>
            <Typography variant="body1">
              Apabila transaksi pembelian tidak berhasil dan/atau dibatalkan, maka tagihan atas transaksi tersebut akan dibatalkan dan dana
              transaksi akan dikembalikan ke limit kartu kredit pembeli di tagihan berikutnya. Ketentuan pada ayat ini tidak berlaku untuk
              transaksi pembelian barang dengan menggunakan kartu kredit yang melanggar ketentuan hukum dan/atau syarat ketentuan Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Apabila seluruh transaksi dalam satu pembayaran yang menggunakan kartu kredit dibatalkan, maka biaya layanan tersebut akan
              dikembalikan ke limit kartu kredit Pengguna. Namun apabila hanya sebagian transaksi dalam satu pembayaran yang menggunakan
              kartu kredit dibatalkan, maka biaya layanan tersebut tidak akan ikut serta dikembalikan.
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">G. Promo</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Oxone sewaktu-waktu dapat mengadakan kegiatan promosi (selanjutnya disebut sebagai “Promo”) dengan Syarat dan Ketentuan yang
              mungkin berbeda pada masing-masing kegiatan Promo. Pengguna dihimbau untuk membaca dengan seksama Syarat dan Ketentuan Promo
              tersebut.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Apabila terdapat transaksi Promo yang mempergunakan metode pembayaran kartu kredit yang melanggar Syarat dan Ketentuan Oxone,
              maka akan merujuk pada Syarat dan Ketentuan Poin F. Kartu Kredit.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Oxone berhak, tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan termasuk namun tidak terbatas pada
              menarik subsidi atau cashback, membatalkan benefit points, pencabutan Promo, membatalkan transaksi, menahan dana, menutup
              akun, serta hal-hal lainnya jika ditemukan adanya manipulasi, penggunaan resi yang tidak valid pada mayoritas transaksi,
              pelanggaran maupun pemanfaatan Promo untuk keuntungan pribadi Pengguna, maupun indikasi kecurangan atau pelanggaran
              pelanggaran Syarat dan Ketentuan Oxone dan ketentuan hukum yang berlaku di wilayah negara Indonesia.{' '}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Dalam hal diadakannya Promo Gratis/Potongan Ongkos Kirim, Jjka terdapat selisih antara berat Barang pada deskripsi produk
              dengan berat Barang yang dikirimkan oleh Penjual melalui jasa kurir/logistik, maka subsidi ongkos kirim yang diberikan akan
              merujuk kepada berat Barang pada data pihak kurir/logistik;{' '}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna hanya boleh menggunakan 1 (satu) akun Oxone untuk mengikuti setiap promo Oxone. Jika ditemukan pembuatan lebih dari 1
              (satu) akun oleh 1 (satu) Pengguna yang sama dan/atau nomor handphone yang sama dan/atau alamat yang sama dan/atau ID
              pelanggan yang sama dan/atau identitas pembayaran yang sama dan/atau riwayat transaksi yang sama, maka Pengguna tidak berhak
              mendapatkan manfaat dari promo Oxone.
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">H. Pengiriman Barang</Typography>

        <ol>
          <li>
            <Typography variant="body1">
              Pengiriman Barang dalam sistem Oxone wajib menggunakan jasa perusahaan ekspedisi yang telah mendapatkan verifikasi rekanan
              Oxone yang dipilih oleh Pembeli.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Setiap ketentuan berkenaan dengan proses pengiriman Barang adalah wewenang sepenuhnya penyedia jasa layanan pengiriman Barang.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Pengguna memahami dan menyetujui bahwa setiap permasalahan yang terjadi pada saat proses pengiriman Barang oleh penyedia jasa
              layanan pengiriman Barang adalah merupakan tanggung jawab penyedia jasa layanan pengiriman.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Dalam hal diperlukan untuk dilakukan proses pengembalian barang, maka Pengguna diwajibkan untuk melakukan pengiriman barang
              langsung ke PT Octa Utama.
            </Typography>
          </li>
        </ol>

        <Typography variant="h2">Ketentuan Lain</Typography>

        <Typography variant="body1">
          Segala hal yang belum dan/atau tidak diatur dalam syarat dan ketentuan khusus dalam fitur tersebut maka akan sepenuhnya merujuk
          pada syarat dan ketentuan Oxone secara umum. Penolakan Jaminan Dan Batasan Tanggung Jawab Oxone adalah portal aplikasi dengan
          model e-commerce atau toko digital, yang menyediakan layanan kepada Pengguna untuk dapat Pembeli di apliaksi Oxone. Dengan
          demikian transaksi yang terjadi adalah transaksi member Oxone, sehingga Pengguna memahami bahwa batasan tanggung jawab Oxone
          secara proporsional adalah sebagai penyedia barang pada portal aplikasi. Oxone selalu berupaya untuk menjaga Layanan Oxone aman,
          nyaman, dan berfungsi dengan baik, tapi kami tidak dapat menjamin operasi terus-menerus atau akses ke Layanan kami dapat selalu
          sempurna. Informasi dan data dalam aplikasi Oxone memiliki kemungkinan tidak terjadi secara real time. Pengguna setuju bahwa Anda
          memanfaatkan Layanan Oxone atas risiko Pengguna sendiri, dan Layanan Oxone diberikan kepada Anda pada <b>SEBAGAIMANA ADANYA</b>{' '}
          dan <b>SEBAGAIMANA TERSEDIA</b>. Sejauh diizinkan oleh hukum yang berlaku, Oxone (termasuk Induk Perusahaan, direktur, dan
          karyawan) adalah tidak bertanggung jawab, dan Anda setuju untuk tidak menuntut Oxone bertanggung jawab, atas segala kerusakan atau
          kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang
          diakibatkan secara langsung atau tidak langsung dari :
        </Typography>

        <ol>
          <li>
            <Typography variant="body1">Penggunaan atau ketidakmampuan Pengguna dalam menggunakan Layanan Oxone.</Typography>
          </li>
          <li>
            <Typography variant="body1">Harga, Pengiriman atau petunjuk lain yang tersedia dalam layanan Oxone.</Typography>
          </li>
          <li>
            <Typography variant="body1">Keterlambatan atau gangguan dalam Layanan Oxone. </Typography>
          </li>
          <li>
            <Typography variant="body1">Kelalaian dan kerugian yang ditimbulkan oleh masing-masing Pengguna.</Typography>
          </li>
          <li>
            <Typography variant="body1">Kualitas Barang.</Typography>
          </li>
          <li>
            <Typography variant="body1">Pengiriman Barang.</Typography>
          </li>
          <li>
            <Typography variant="body1">Pelanggaran Hak atas Kekayaan Intelektual.</Typography>
          </li>
          <li>
            <Typography variant="body1">Setiap penyalahgunaan Barang yang sudah dibeli pihak Pengguna.</Typography>
          </li>
          <li>
            <Typography variant="body1">
              Kerugian akibat pembayaran tidak resmi kepada pihak lain selain ke Rekening Resmi Oxone, yang dengan cara apapun
              mengatas-namakan Oxone ataupun kelalaian penulisan rekening dan/atau informasi lainnya dan/atau kelalaian pihak bank.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Virus atau perangkat lunak berbahaya lainnya (bot, script, automation tool, hacking tool) yang diperoleh dengan mengakses,
              atau menghubungkan ke layanan Oxone.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam Layanan Oxone.</Typography>
          </li>
          <li>
            <Typography variant="body1">Kerusakan pada perangkat keras Anda dari penggunaan setiap Layanan Oxone. </Typography>
          </li>
          <li>
            <Typography variant="body1">Tindak penegakan yang diambil sehubungan dengan akun Pengguna.</Typography>
          </li>
          <li>
            <Typography variant="body1"> Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun pengguna</Typography>
          </li>
        </ol>

        <Typography variant="h2">Pilihan Hukum</Typography>

        <Typography variant="body1">
          Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan
          hukum. Anda setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara
          apapun berhubungan dengan Aplikasi dan/atau Perjanjian ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik
          Indonesia. Pembaharuan Syarat & ketentuan mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya.
          Oxone menyarankan agar anda membaca secara seksama dan memeriksa halaman Syarat & ketentuan ini dari waktu ke waktu untuk
          mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan layanan Oxone, maka pengguna dianggap menyetujui
          perubahan-perubahan dalam Syarat & Ketentuan.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermAndCondition;
