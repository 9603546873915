import { Reducer } from 'redux';
import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  user: {
    id: null,
    name: null,
    email: null,
  },
  isAuthenticated: false,
  isLoginLoading: false,
  isLoginError: false,
  isCheckAuthLoading: false,
  isCheckAuthError: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.CHECK_AUTHENTICATE:
      return { ...state, isCheckAuthLoading: true };
    case AuthTypes.AUTHENTICATE:
      return { ...state, isCheckAuthLoading: false, isAuthenticated: true };
    case AuthTypes.UNAUTHENTICATE:
      return { ...state, isCheckAuthLoading: false, isAuthenticated: false };

    case AuthTypes.AUTH_LOGIN:
      return { ...state, isLoginLoading: true };
    case AuthTypes.AUTH_LOGIN_SUCCESS:
      return { ...state, isLoginLoading: false, user: action.payload };
    case AuthTypes.AUTH_LOGIN_FAILED:
      return { ...state, isLoginLoading: false, isLoginError: true };
    default:
      return state;
  }
};

export { reducer as authReducer };
