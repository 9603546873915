import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, Box, Typography, CircularProgress } from '@material-ui/core';
import logoOxone from '../assets/images/logo-oxone-black.svg';
import { convertNumToCurrency } from '../utils/currency';
import { apiGetDetailTransactionInvoiceByInvoice } from '../api/transaction';
import { useLocation, useParams } from 'react-router-dom';
import { getHeaders } from '../utils/auth';
import { convertDateTimeToText } from '../utils/datetime';

const InvoiceDetail = () => {
  const classes = useStyle();
  const { invoiceId }: any = useParams();
  const query: any = useQuery();
  const token = query.get('token');
  const print = query.get('print');

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({
    receiverName: '',
    receiverPhone: '',
    paymentMethod: '',
    buyAt: '',
    paidAt: '',
    address: '',
    expedition: '',
    finalTransactionPrice: 0,
  });
  const [listProduct, setListProduct] = useState<
    {
      productName: string;
      sku: string;
      qty: number;
      weight: number;
      realPrice: number;
      finalPrice: number;
      subtotal: number;
    }[]
  >([]);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingDiscount, setShippingDiscount] = useState(0);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    const headers = await getHeaders(token);
    apiGetDetailTransactionInvoiceByInvoice(invoiceId, headers)
      .then((res) => {
        setDetail({
          receiverName: res.transaction.shipping.expeditionMetadata.receiverName,
          receiverPhone: res.transaction.shipping.expeditionMetadata.receiverPhone,
          paymentMethod: res.invoice.payment.paymentMethod + '-' + res.invoice.payment.paymentVendor,
          buyAt: res.transaction.createdAt as string,
          paidAt: res.invoice.payment.paidAt as string,
          address: res.transaction.shipping.toAddress,
          expedition: res.transaction.shipping.expeditionCode + ' ' + res.transaction.shipping.expeditionService,
          finalTransactionPrice: res.transaction.finalTransactionPrice,
        });

        setListProduct(
          res.transaction.items.map((item) => ({
            productName: item.productMetadata.name,
            sku: item.productMetadata.sku,
            qty: item.qty,
            weight: item.productMetadata.weight,
            realPrice: item.productMetadata.price,
            finalPrice: item.price - item.discount / item.qty,
            subtotal: item.finalTotalPrice,
          })),
        );
        setShippingCost(res.transaction.shipping.shippingCost);
        setShippingDiscount(res.transaction.shipping.shippingDiscount);
        setLoading(false);

        if (print) {
          // setTimeout(() => {
          printMe();
          // }, 2000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.info('error', err);
      });
  };

  const subtotalProductPrice = listProduct.reduce((acc, curr) => acc + curr.subtotal, 0);
  const totalWeight = listProduct.reduce((acc, curr) => acc + curr.weight, 0);

  const printMe = () => {
    const divName = 'printMe';
    const printContents = document.getElementById(divName) ? (document.getElementById(divName) as any).innerHTML : '';
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  };

  if (loading) {
    return (
      <Box display="flex" flex={1} className={classes.containerLoading}>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <CircularProgress />
      </Box>
    );
  } else if (!detail.paidAt) {
    return (
      <Box className={classes.container}>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <Box className={classes.topBorderContainer}></Box>
        <Box display="flex" flex={1} className={classes.containerLoading}>
          <Box className={classes.root}>
            <Typography variant="h1">Not Found</Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box className={classes.container}>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <Box className={classes.container} id="printMe">
        <Box className={classes.topBorderContainer}></Box>
        <Box className={classes.root}>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 1, borderBottomStyle: 'solid' }}>
                <td colSpan={2} className={classes.tableDataLeft}>
                  <Box flex={3} display="flex" flexDirection="row" alignItems="center">
                    <img alt="logo-oxone" className={classes.logo} src={logoOxone} />
                    <Box
                      display="block"
                      style={{ backgroundColor: '#DFDFDF', width: 0.5, height: 70, marginLeft: 20, marginRight: 20 }}
                    ></Box>
                    <Box>Be a chef at your own home</Box>
                  </Box>
                </td>

                <td className={classes.tableDataLeft}></td>

                <td className={classes.tableDataLeft}>Nomor Invoice</td>
                <td className={classes.tableDataRight}>{invoiceId}</td>
              </tr>

              <tr style={{ height: 20 }}>
                <td colSpan={5} />
              </tr>
              <tr>
                <td colSpan={5} style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}></td>
              </tr>
              <tr style={{ height: 20 }}>
                <td colSpan={5} />
              </tr>

              <tr>
                <td className={classes.tableDataLeft}>Nama Penerima</td>
                <td className={classes.tableDataRight}>{detail.receiverName}</td>

                <td width={20} className={classes.tableDataLeft}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>

                <td className={classes.tableDataLeft}>Metode Pembayaran</td>
                <td className={classes.tableDataRight}>{detail.paymentMethod}</td>
              </tr>
              <tr>
                <td className={classes.tableDataLeft}>Tanggal Pembelian</td>
                <td className={classes.tableDataRight}>{convertDateTimeToText(detail.buyAt, 'dd mmm yy hh:mm')}</td>
                <td className={classes.tableDataLeft}>&nbsp;&nbsp;&nbsp;&nbsp;</td>

                <td className={classes.tableDataLeft}>Tanggal Pembayaran</td>
                <td className={classes.tableDataRight}>{convertDateTimeToText(detail.paidAt, 'dd mmm yy hh:mm')}</td>
              </tr>
              <tr>
                <td className={classes.tableDataLeft}>No. Handphone Penerima</td>
                <td className={classes.tableDataRight}>{detail.receiverPhone}</td>
                <td className={classes.tableDataLeft}>&nbsp;&nbsp;&nbsp;&nbsp;</td>

                <td className={classes.tableDataLeft}>Kurir</td>
                <td className={classes.tableDataRight}>{detail.expedition.toUpperCase()}</td>
              </tr>
              <tr>
                <td className={classes.tableDataLeft}>Alamat Penerima</td>
                <td className={classes.tableDataRight}>{detail.address}</td>
                <td className={classes.tableDataLeft}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td className={classes.tableDataLeft}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td className={classes.tableDataLeft}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box className={classes.root}>
          <Typography variant="h6">Rincian Pesanan</Typography>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th
                  style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                  className={classes.tableDataProduct}
                  align="left"
                >
                  Nama Produk
                </th>
                <th
                  style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                  className={classes.tableDataProduct}
                  align="center"
                >
                  Qty
                </th>
                <th
                  style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                  className={classes.tableDataProduct}
                  align="left"
                >
                  Berat
                </th>
                <th
                  style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                  className={classes.tableDataProduct}
                  align="right"
                >
                  Harga Produk
                </th>
                <th
                  style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                  className={classes.tableDataProduct}
                  align="right"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {listProduct.map((item, index) => (
                <tr key={'product_item_' + index}>
                  <td
                    width="30%"
                    style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                    className={classes.tableDataProduct}
                    align="left"
                  >
                    {item.sku ? item.sku + ' ' : ''}
                    {item.productName}
                  </td>
                  <td
                    style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                    className={classes.tableDataProduct}
                    align="center"
                  >
                    {item.qty}
                  </td>
                  <td
                    style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                    className={classes.tableDataProduct}
                    align="left"
                  >
                    {(item.weight / 1000).toFixed(2)}kg
                  </td>
                  <td
                    style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                    className={classes.tableDataProduct}
                    align="right"
                  >
                    {convertNumToCurrency(item.finalPrice)}
                  </td>
                  <td
                    style={{ borderBottomColor: '#DFDFDF', borderBottomWidth: 0.5, borderBottomStyle: 'solid' }}
                    className={classes.tableDataProduct}
                    align="right"
                  >
                    {convertNumToCurrency(item.finalPrice * item.qty)}
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan={2} />
                <td colSpan={2} className={classes.tableDataProduct} align="left">
                  Subtotal Harga Produk
                </td>
                <td className={classes.tableDataProduct} align="right">
                  {convertNumToCurrency(subtotalProductPrice)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td colSpan={2} className={classes.tableDataProduct} align="left">
                  Subtotal Ongkos Kirim ({(totalWeight / 1000).toFixed(2)}kg)
                </td>
                <td className={classes.tableDataProduct} align="right">
                  {convertNumToCurrency(shippingCost)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td colSpan={2} className={classes.tableDataProduct} align="left">
                  Diskon Ongkos Kirim
                </td>
                <td className={classes.tableDataProduct} align="right">
                  -{convertNumToCurrency(shippingDiscount)}
                </td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td colSpan={2} className={classes.tableDataProduct} align="left" style={{ fontWeight: 'bold' }}>
                  Total Pembayaran
                </td>
                <td className={classes.tableDataProduct} align="right" style={{ fontWeight: 'bold' }}>
                  {convertNumToCurrency(detail.finalTransactionPrice)}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDetail;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    containerLoading: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '10%',
    },
    container: {
      width: '100%',
    },
    root: { padding: theme.spacing(2, 8) },
    topBorderContainer: {
      backgroundColor: theme.palette.primary.main,
      height: 20,
    },
    logo: {
      width: 150,
    },
    containerHeader: {
      padding: theme.spacing(2),
    },
    tableDataLeft: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    tableDataRight: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 25,
      paddingRight: 25,
    },
    tableDataProduct: {
      borderBottomColor: '#DFDFDF',
      borderBottomWidth: 0.5,
      borderBottomStyle: 'solid',
      paddingTop: 12,
      paddingBottom: 12,
    },
  }),
);

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
