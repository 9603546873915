import {
  Button,
  TextField,
  Grid,
  Box,
  createStyles,
  makeStyles,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Theme,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import useScript from '../hooks/useScript';
import MaskedInput from 'react-text-mask';
import { useQuery } from './InvoiceDetail';
import iconChip from '../assets/icons/icon-chip.svg';
import iconCVV from '../assets/icons/icon-cvv.svg';

function removeSpace(str: string) {
  return str.replace(/\s/g, '');
}

const FONT_SIZE = 20;

export default function PaymentCreditCard() {
  const classes = useStyles();
  const query: any = useQuery();
  const saved_token_id = query.get('saved_token_id');
  const masked_card = query.get('masked_card');
  const expiry_month = query.get('expiry_month');
  const expiry_year = query.get('expiry_year');

  const midtransScriptUrl = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
  const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_CLIENT_KEY;
  const environment = process.env.REACT_APP_MIDTRANS_ENVIRONMENT;

  useScript(midtransScriptUrl, [
    {
      key: 'id',
      value: 'midtrans-script',
    },
    {
      key: 'data-environment',
      value: environment || '',
    },
    {
      key: 'data-client-key',
      value: myMidtransClientKey || '',
    },
  ]);

  const [card_number_text, setcard_number_text] = useState('');
  const [card_number, setcard_number] = useState('');
  const [card_exp_month_year, setcard_exp_month_year] = useState('');
  const [card_exp_month, setcard_exp_month] = useState('');
  const [card_exp_year, setcard_exp_year] = useState('');
  const [card_cvv, setcard_cvv] = useState('');

  // callback functions
  const options = {
    onSuccess: function (response: any) {
      // Success to get card token_id, implement as you wish here
      console.log('Success to get card token_id, response:', response);
      const token_id = response.token_id;

      console.log('This is the card token_id:', token_id);
      // Implement sending the token_id to backend to proceed to next step

      // alert('RES: ' + JSON.stringify(response));

      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(response));
      }
    },
    onFailure: function (response: any) {
      // Fail to get card token_id, implement as you wish here
      console.log('Fail to get card token_id, response:', response);

      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ ...response, isError: true }));
      }

      // you may want to implement displaying failure message to customer.
      // Also record the error message to your log, so you can review
      // what causing failure on this transaction.
    },
  };

  const optionsRegisterCard = {
    onSuccess: function (response: any) {
      // Implement success handling here, save the `saved_token_id` to your database
      console.log('Saved Token ID:', response.saved_token_id);
      // if ((window as any).ReactNativeWebView) {
      //   (window as any).ReactNativeWebView.postMessage(
      //     JSON.stringify({
      //       ...response,
      //       action: 'register-card',
      //       expiry_month: card_exp_month,
      //       expiry_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
      //     }),
      //   );
      // }

      const optionsAfterRegister = {
        onSuccess: function (responseAfterRegister: any) {
          // Success to get card token_id, implement as you wish here
          console.log('Success to get card token_id, responseAfterRegister:', responseAfterRegister);
          const token_id = responseAfterRegister.token_id;

          console.log('This is the card token_id:', token_id);
          // Implement sending the token_id to backend to proceed to next step

          // alert('RES: ' + JSON.stringify(responseAfterRegister));

          const result = {
            ...responseAfterRegister,
            saved_token_id: response.saved_token_id,
            masked_card: response.masked_card,
            expiry_month: card_exp_month,
            expiry_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
          };
          console.info('success register result', result);
          if ((window as any).ReactNativeWebView) {
            (window as any).ReactNativeWebView.postMessage(JSON.stringify(result));
          }
        },
        onFailure: function (responseAfterRegister: any) {
          // Fail to get card token_id, implement as you wish here
          console.log('Fail to get card token_id, responseAfterRegister:', responseAfterRegister);

          const result = {
            ...responseAfterRegister,
            saved_token_id: response.saved_token_id,
            masked_card: response.masked_card,
            expiry_month: card_exp_month,
            expiry_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
            isError: true,
          };
          console.info('success register result', result);
          if ((window as any).ReactNativeWebView) {
            (window as any).ReactNativeWebView.postMessage(JSON.stringify(result));
          }

          // you may want to implement displaying failure message to customer.
          // Also record the error message to your log, so you can review
          // what causing failure on this transaction.
        },
      };

      (window as any).MidtransNew3ds.getCardToken(
        {
          // card_number,
          // card_exp_month,
          // card_exp_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
          token_id: response.saved_token_id,
          card_cvv,
        },
        optionsAfterRegister,
      );
    },
    onFailure: function (response: any) {
      // Implement error handling here
      console.log('Fail to get saved card token', response.status_message);

      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(
          JSON.stringify({
            ...response,
            isError: true,
            action: 'register-card',
            expiry_month: card_exp_month,
            expiry_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
          }),
        );
      }
    },
  };

  return (
    <Box>
      <Box style={{ padding: 20 }} className={classes.card}>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img alt="OXONE" className={classes.logo} src={iconChip} />
            </Grid>
            {saved_token_id ? (
              <Grid item xs={12}>
                <Typography variant="body1" component="span">
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    {showMaskedCard(masked_card)}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              false
            )}
            {saved_token_id ? (
              false
            ) : (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="formatted-text-expired-mask-input">Card Number</InputLabel>
                  <Input
                    fullWidth
                    value={card_number_text}
                    onChange={(event) => {
                      setcard_number(removeSpace(event.target.value));
                      setcard_number_text(event.target.value);
                    }}
                    name="textmask"
                    id="formatted-text-mask-input"
                    inputComponent={InputCreditCardMaskCustom as any}
                    placeholder="xxxx xxxx xxxx xxxx"
                    style={{ fontSize: FONT_SIZE }}
                  />
                </FormControl>
              </Grid>
            )}
            {saved_token_id ? (
              false
            ) : (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="formatted-text-expired-mask-input">Expired (mm/yy)</InputLabel>
                  <Input
                    fullWidth
                    value={card_exp_month_year}
                    onChange={(event) => {
                      setcard_exp_month_year(event.target.value);
                      const exp = event.target.value.split('/');
                      setcard_exp_year(exp[1]);
                      setcard_exp_month(exp[0]);
                    }}
                    name="textmask"
                    id="formatted-text-expired-mask-input"
                    inputComponent={InputCreditCardExpYearMonthMaskCustom as any}
                    placeholder="mm/yy"
                    style={{ fontSize: FONT_SIZE }}
                  />
                </FormControl>
              </Grid>
            )}
            {saved_token_id ? (
              <Grid item xs={6}>
                <Typography>Valid Thru</Typography>
                <Typography>
                  {expiry_month}/{expiry_year}
                </Typography>
              </Grid>
            ) : (
              false
            )}
            <Grid item xs={6}>
              <Box display="flex" flexDirection="row">
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      type="password"
                      onChange={(event: any) => setcard_cvv(event.target.value)}
                      value={card_cvv}
                      InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="CVV"
                      style={{ fontSize: FONT_SIZE }}
                    />
                  </FormControl>
                </Box>
                <Box display="flex" flexShrink justifyContent="center" alignItems="center">
                  <img alt="CVV" className={classes.cvv} src={iconCVV} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Box style={{ marginTop: 10 }}>
        <CustomButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={async () => {
            if (saved_token_id) {
              await (window as any).MidtransNew3ds.getCardToken(
                {
                  token_id: saved_token_id,
                  card_cvv,
                },
                options,
              );
            } else {
              (window as any).MidtransNew3ds.registerCard(
                {
                  card_number,
                  card_exp_month,
                  card_exp_year: String(new Date().getFullYear()).substring(0, 2) + String(card_exp_year),
                  card_cvv,
                },
                optionsRegisterCard,
              );
            }
          }}
        >
          SIMPAN
        </CustomButton>
      </Box>

      <Box className={classes.appVersion}>
        <Typography variant="subtitle2" align="center">
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  );
}

interface InputCreditCardMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function InputCreditCardMaskCustom(props: InputCreditCardMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

interface InputCreditCardExpYearMonthMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function InputCreditCardExpYearMonthMaskCustom(props: InputCreditCardExpYearMonthMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /\d/, '/', /\d/, /\d/]}
      showMask
    />
  );
}

function showMaskedCard(text: string) {
  return text.substring(0, 4) + ' **** **** ' + text.substring(text.length - 4, text.length);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.background.paper,
      borderColor: '#C4C4C4',
      borderWidth: 1,
      borderStyle: 'solid',
      padding: 10,
      borderRadius: 8,
    },
    logo: {
      width: 60,
      height: 40,
    },
    cvv: {
      width: 52,
      height: 40,
      alignSelf: 'flex-end',
    },
    resize: {
      fontSize: FONT_SIZE,
    },
    appVersion: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  }),
);

const CustomButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FABB43',
      color: '#444444',
      '&:hover': {
        backgroundColor: '#FABB43',
        color: '#444444',
      },
    },
  }),
)(Button);
