import * as React from 'react';
import { makeStyles, Theme, createStyles, Button, Card, CardContent, Divider, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import NumberFormat from 'react-number-format';
import InputQuantity from '../Form/InputQuantity';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    addProductCard: {
      position: 'sticky',
      top: 90,
      borderRadius: 10,
    },
    title: {
      fontWeight: 700,
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    subtotal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subtotalValue: {
      fontWeight: 700,
    },
    subtotalLabel: {
      fontSize: 12,
      color: grey[400],
    },
    buttonSubmit: {
      boxShadow: 'none',
      width: '100%',
      textTransform: 'none',
      height: 42,
      borderRadius: 10,
    },
  }),
);

const AddProduct = () => {
  const classes = useStyle();
  const [qty, setQty] = React.useState<number>(1);
  const productPrice = 450000;
  const [subTotal, setSubTotal] = React.useState<number>(0);

  React.useEffect(() => {
    setSubTotal(productPrice * qty);
  }, []);

  function handleQtyChange(value: number) {
    setQty(value);
    setSubTotal(productPrice * value);
  }

  return (
    <div>
      <Card variant="outlined" className={classes.addProductCard}>
        <CardContent>
          <Typography variant="body1" component="p" className={classes.title}>
            Add Product
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" component="p" className={classes.subtitle}>
            Product Quantity
          </Typography>

          <InputQuantity qty={qty} handleQtyChange={(newQty: number) => handleQtyChange(newQty)} />

          <div className={classes.subtotal}>
            <Typography variant="body1" component="p" className={classes.subtotalLabel}>
              Subtotal
            </Typography>
            <NumberFormat
              value={subTotal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'Rp'}
              renderText={(value) => (
                <Typography variant="subtitle1" component="p" className={classes.subtotalValue}>
                  {value}
                </Typography>
              )}
            />
          </div>
          <Divider className={classes.divider} />
          <Button disabled={subTotal === 0} color="secondary" variant="contained" className={classes.buttonSubmit}>
            Add to Cart
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddProduct;
