import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import COLORS from './colors';

const defaultTheme: ThemeOptions = {
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.greyDark,
    },
    error: {
      main: COLORS.error,
    },
    background: {
      // default: COLORS.accent,
      default: '#F6F6F6',
    },
    text: {
      primary: COLORS.primary,
      secondary: COLORS.grey,
    },
  },
  typography: {
    fontFamily: ['Open Sans'].join(','),
    h1: {
      fontSize: 25,
      lineHeight: 2,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 21,
      lineHeight: 2,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 20,
      lineHeight: 2,
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 18,
      lineHeight: 2,
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 16,
      lineHeight: 2,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 14,
      lineHeight: 2,
    },
    body2: {
      fontSize: 13,
      lineHeight: 2,
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 11,
      lineHeight: 1,
    },
  },
};

// Create a theme instance.
export const theme = createMuiTheme(defaultTheme);
