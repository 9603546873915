import { AppBar, Toolbar, IconButton, InputBase, ListItemIcon, ListItemText, Popover, List, ListItem, Badge } from '@material-ui/core';
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { checkLogin } from '../../store/auth/actions';
import { Search, ShoppingCart, User, LogOut, Grid as GridIcon } from 'react-feather';
import logoOxone from '../../assets/images/logo-oxone.svg';
import useNavbarStyles from './NavBar.style';
import { grey } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

interface StateProps {
  isAuthenticated: boolean | null;
}

interface DispatchProps {
  checkLogin: () => void;
}

type Props = StateProps & DispatchProps;

const NavBar: React.FC<Props> = () => {
  const classes = useNavbarStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarSection}>
          <div className={classes.leftSection}>
            <IconButton
              edge="start"
              disableFocusRipple
              disableRipple
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => history.push('/catalog')}
            >
              <img alt="logo-oxone" className={classes.logo} src={logoOxone} />
            </IconButton>
          </div>
          <div className={classes.inputContainer}>
            <InputBase
              className={classes.input}
              placeholder="Search product here..."
              inputProps={{ 'aria-label': 'naked' }}
              startAdornment={<Search className={classes.inputIcon} width={20} color={grey[400]} />}
            />
          </div>
          <div className={classes.rightSection}>
            <IconButton
              onClick={() => history.push('/cart')}
              edge="start"
              className={classes.menuFirstButton}
              color="inherit"
              aria-label="cart"
            >
              <Badge badgeContent={4} color="secondary">
                <ShoppingCart size={20} />
              </Badge>
            </IconButton>
            <IconButton
              onClick={() => history.push('/order-list')}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="cart"
            >
              <GridIcon size={20} />
            </IconButton>
            <IconButton edge="start" color="inherit" aria-label="cart" onClick={handleClick}>
              <User size={20} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Toolbar />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-label="main mailbox folders" className={classes.popoverList}>
          <ListItem
            button
            onClick={() => {
              handleClose();
              history.push('/profile');
            }}
          >
            <ListItemIcon className={classes.popoverItemIcon}>
              <User color={'#fff'} size={20} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button>
            <ListItemIcon className={classes.popoverItemIcon}>
              <LogOut color={'#fff'} size={20} />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({ isAuthenticated: state.auth.isAuthenticated });

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    checkLogin: () => dispatch(checkLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
