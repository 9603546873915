import * as React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    dialogLocation: {
      borderRadius: 10,
      padding: theme.spacing(3),
      '& .MuiPaper-root': {
        borderRadius: 10,
        minWidth: 650,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        padding: theme.spacing(3),
      },
      '& .MuiButton-root': {
        flex: 1,
        borderRadius: 10,
        height: 45,
        textTransform: 'capitalize',
      },
      '& .MuiList-root': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    dialogTitle: {
      fontSize: 20,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontWeight: 700,
    },
    dialogContent: {
      fontSize: 14,
    },
    iconSuccess: {
      borderRadius: 100,
      border: `5px solid ${grey[200]}`,
      padding: theme.spacing(2),
    },
    listRoot: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

interface DialogLocationProps {
  open: boolean;
  handleClose: () => void;
}

const DialogLocation: React.FC<DialogLocationProps> = (props: DialogLocationProps) => {
  const classes = useStyle();
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Dialog
      className={classes.dialogLocation}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography variant="h6" className={classes.dialogTitle}>
          Pilih Lokasi Pengiriman
        </Typography>
        <List className={classes.listRoot}>
          {[0, 1, 2].map((value) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <div>
                    <Typography>Cabang Nusa Indah 1</Typography>
                    <Typography>Jl. Bintang No.5, RT.3/RW.4, Sudimara Pinang, Pinang, Kota Tangerang, Banten 15145, Indonesia</Typography>
                  </div>
                  <Typography>02167843266</Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={props.handleClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLocation;
