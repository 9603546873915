import * as React from 'react';
import { makeStyles, createStyles, Box, CardContent, Card } from '@material-ui/core';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';

const useStyle = makeStyles(() =>
  createStyles({
    container: {},
    root: {},
  }),
);

const OrderDetail = () => {
  const classes = useStyle();

  function handleClick() {
    console.info('handle');
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <BreadCrumb title={'Order List'} handleClick={handleClick} />

        <Card>
          <CardContent></CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default OrderDetail;
