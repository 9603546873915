import { Breadcrumbs, Typography, makeStyles, createStyles } from '@material-ui/core';
import * as React from 'react';
import { ChevronLeft } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';

const useStyle = makeStyles(() =>
  createStyles({
    back: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:active > svg': {
        color: '#000',
      },
    },
  }),
);

interface BreadcrumbProps {
  handleClick: () => void;
  title: string;
}

function BreadCrumb({ title }: BreadcrumbProps) {
  const classes = useStyle();
  const history = useHistory();

  console.info(history);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link className={classes.back} color="inherit" to="/catalog">
        <ChevronLeft height={20} />
        <Typography color="textPrimary">Catalog</Typography>
      </Link>
      <Typography color="textSecondary">{title}</Typography>
    </Breadcrumbs>
  );
}

export default BreadCrumb;
