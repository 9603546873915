import { withStyles, Button, Theme, createStyles } from '@material-ui/core';

const ButtonLogin = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 100,
      borderColor: '#fff',
      borderWidth: 2,
      borderStyle: 'solid',
      color: 'white',
      height: 50,
      padding: '0 30px',
      fontSize: 16,
      marginTop: theme.spacing(4),
      width: '100%',
      transition: theme.transitions.create(['opacity']),
      '&:hover': {
        opacity: 0.7,
      },
    },
    label: {
      textTransform: 'capitalize',
    },
  }),
)(Button);

export default ButtonLogin;
