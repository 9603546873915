const listMonth: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Ags', 'Sept', 'Okt', 'Nov', 'Des'];

export function convertDateTimeToText(
  date: string | Date,
  format: 'dd mmm' | 'dd-mm-yyyy' | 'dd-mm-yy' | 'dd-mm-yy hh:mm' | 'dd mmm yyyy' | 'dd mmm yy hh:mm' = 'dd-mm-yyyy',
) {
  date = new Date(date);
  let dateText: string = date.getDate().toString();
  let monthText: string = (date.getMonth() + 1).toString();
  let yearText: string = date.getFullYear().toString();

  let hourText: string = (date.getHours() + 1).toString();
  let minuteText: string = date.getMinutes().toString();

  if (dateText.length === 1) {
    dateText = '0' + dateText;
  }

  if (format.includes('mmm')) {
    monthText = listMonth[Number(monthText) - 1];
  } else if (monthText.length === 1) {
    monthText = '0' + monthText;
  }
  if (format === 'dd-mm-yy' || format === 'dd-mm-yy hh:mm') {
    yearText = yearText.substr(2, 2);
  }

  if (hourText.length === 1) {
    hourText = '0' + hourText;
  }
  if (minuteText.length === 1) {
    minuteText = '0' + minuteText;
  }

  if (format.includes('-')) {
    if (format === 'dd-mm-yy' || format === 'dd-mm-yyyy') {
      return dateText + '-' + monthText + '-' + yearText;
    } else {
      return dateText + '-' + monthText + '-' + yearText + ' ' + hourText + ':' + minuteText;
    }
  } else {
    if (format === 'dd mmm yy hh:mm') {
      return dateText + ' ' + monthText + ' ' + yearText + ' ' + hourText + ':' + minuteText;
    } else if (format === 'dd mmm') {
      return dateText + ' ' + monthText;
    } else {
      return dateText + ' ' + monthText + ' ' + yearText;
    }
  }
}
