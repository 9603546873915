import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ApplicationState } from '../store';

interface StateProps {
  exact?: boolean;
  isAuthenticated: boolean | null;
  path: string;
  component: React.FC;
}

const PrivateRoute: React.FC<StateProps> = ({ isAuthenticated, ...otherProps }: StateProps) => {
  if (!isAuthenticated) {
    console.info('redirect', isAuthenticated);
    return <Redirect to="/log-in" />;
  }
  console.info('private route', isAuthenticated);
  return <Route {...otherProps} />;
};

const mapStateToProps = (state: ApplicationState) => ({ isAuthenticated: state.auth.isAuthenticated });

export default connect(mapStateToProps)(PrivateRoute);
