import { useEffect } from 'react';

const useScript = (
  url: string,
  attributes: Array<{
    key: string;
    value: string;
  }>,
) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    for (const att of attributes) {
      script.setAttribute(att.key, att.value);
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
