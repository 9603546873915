import * as React from 'react';
import ProductList from '../components/ProductList/ProductList';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Filter from '../components/Filter/Filter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      display: 'flex',
      width: '100%',
      paddingTop: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1440,
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 1190,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
    },
  }),
);

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Filter />
      <ProductList />
    </div>
  );
};

export default Home;
