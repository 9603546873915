export interface ValueProps {
  isValid: boolean;
  value: string;
}

export interface LoginProps {
  email: ValueProps;
  password: ValueProps;
}

export interface LoginError {
  email: string;
  password: string;
}

export const initialLoginError: LoginError = {
  email: '',
  password: '',
};

export const initialLoginValue: LoginProps = {
  email: {
    value: '',
    isValid: false,
  },
  password: {
    value: '',
    isValid: false,
  },
};
