import { createStore, applyMiddleware, Store } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { AuthState } from './auth/types';
import rootReducer from './rootReducers';
import rootSaga from './rootSagas';

export interface ApplicationState {
  auth: AuthState;
}
const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const store: Store<ApplicationState> = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

sagaMiddleware.run(rootSaga);

export default store;
