import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useNavbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    appBar: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor: theme.palette.primary.main,
      },
    },
    logo: {
      width: 80,
    },
    popoverList: {
      width: 200,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    inputContainer: {
      [theme.breakpoints.up('lg')]: {
        minWidth: 1440,
      },
      [theme.breakpoints.down('lg')]: {
        minWidth: 960,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 800,
      },
    },
    input: {
      backgroundColor: grey[50],
      color: theme.palette.primary.main,
      borderRadius: 10,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: 5,
      paddingBottom: 5,
      width: '100%',
    },
    inputIcon: {
      marginRight: 10,
    },
    toolbarSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    leftSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    rightSection: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    menuFirstButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    popover: {
      pointerEvents: 'none',
    },
    popoverItemIcon: {
      minWidth: 36,
    },
    paper: {
      padding: theme.spacing(1),
    },
  }),
);

export default useNavbarStyles;
