import * as React from 'react';
import { makeStyles, Theme, createStyles, Paper, Typography, Divider, Button, TextField } from '@material-ui/core';
import ImagePlaceholder from '../assets/images/placeholder-product.png';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    root: {
      margin: '0 auto',
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 980,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
      marginBottom: theme.spacing(16),
    },
    paperRoot: {
      borderRadius: 10,
      padding: theme.spacing(3),
      '& .MuiDivider-root': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    sectionTop: {
      display: 'grid',
      gridTemplateColumns: '250px 80px 1fr 1fr',
      columnGap: 24,
      '& img': {
        width: '100%',
        // marginRight: theme.spacing(8),
      },
      '& .MuiTypography-body2': {
        color: grey[400],
      },
      '& .MuiTypography-body1': {
        marginBottom: theme.spacing(3),
      },
    },
    sectionBottom: {
      display: 'grid',
      gridTemplateColumns: '330px 1fr',
      columnGap: 48,
    },
    formGroup: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: 20,
    },
  }),
);

const Profile = () => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Typography variant="h6">Profile</Typography>
        <Typography variant="body2" style={{ marginBottom: 24, color: grey[500] }}>
          Lorem ipsum dolor sit amet
        </Typography>
        <Paper className={classes.paperRoot}>
          <div className={classes.sectionTop}>
            <img src={ImagePlaceholder} />
            <div />
            <div>
              <Typography variant="body2">Name</Typography>
              <Typography variant="body1">Content</Typography>
              <Typography variant="body2">Phone</Typography>
              <Typography variant="body1">Content</Typography>
              <Typography variant="body2">Email</Typography>
              <Typography variant="body1">Content</Typography>
              <Typography variant="body2">Role</Typography>
              <Typography variant="body1">Content</Typography>
            </div>
            <div>
              <Typography variant="body2">Distributor</Typography>
              <Typography variant="body1">Content</Typography>
              <Typography variant="body2">Phone</Typography>
              <Typography variant="body1">Content</Typography>
              <Typography variant="body2">Address</Typography>
              <Typography variant="body1">Content</Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.sectionBottom}>
            <div />
            <div>
              <Typography variant="h6">Change Password</Typography>
              <Typography variant="body2">Lorem ipsum dolor sit amet</Typography>
              <div className={classes.formGroup}>
                <TextField label="New Password" placeholder="Password" />
                <TextField label="Re-Type Password" placeholder="Password" />
                <Button variant="contained" color="primary">
                  Update Password
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default Profile;
