/**
 * Action types
 */
export enum AuthTypes {
  CHECK_AUTHENTICATE = '@auth/CHECK_AUTHENTICATE',
  AUTHENTICATE = '@auth/AUTHENTICATE',
  UNAUTHENTICATE = '@auth/UNAUTHENTICATE',

  AUTH_LOGIN = '@auth/AUTH_LOGIN',
  AUTH_LOGIN_SUCCESS = '@auth/AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAILED = '@auth/AUTH_LOGIN_FAILED',

  AUTH_LOGOUT = '@auth/AUTH_LOGOUT',
  AUTH_LOGOUR_SUCCESS = '@auth/AUTH_LOGOUR_SUCCESS',
  AUTH_LOGOUR_FAILED = '@auth/AUTH_LOGOUR_FAILED',
}

/**
 * Data types
 */
export interface ILogin {
  email: string;
  password: string;
}
export interface IUser {
  id: number | string | null;
  name: string | null;
  email: string | null;
}

/**
 * State type
 */
export interface AuthState {
  readonly user: IUser;
  readonly isAuthenticated: boolean | null;
  readonly isCheckAuthLoading: boolean;
  readonly isCheckAuthError: boolean;
  readonly isLoginLoading: boolean;
  readonly isLoginError: boolean;
}
