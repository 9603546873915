import axios from 'axios';
import { GeneralResponse } from './generalResponse';

export enum ETransactionStatus {
  created = 'created', // menunggu pembayaran
  payment_failed = 'payment_failed', // pembayaran bermasalah
  paid = 'paid', // dibayar
  process = 'process', // diproses

  canceled = 'canceled', // dibatalkan
  rejected = 'rejected', // ditolak
  expired = 'expired', // expired

  sent = 'sent', // dikirim
  received = 'received', //diterima
}

export const TRANSACTION_STATUS = {
  created: 'Menunggu Pembayaran', // menunggu pembayaran
  payment_failed: 'Transaksi Gagal', // pembayaran bermasalah
  paid: 'Dibayar', // dibayar
  process: 'Diproses', // diproses

  canceled: 'Dibatalkan', // dibatalkan
  rejected: 'Ditolak', // ditolak
  expired: 'Kadaluarsa', // expired

  sent: 'Dikirim', // dikirim
  received: 'Diterima', //diterima
};

export type Transaction = {
  transactionId: string;
  externalId: string;
  transactionPrice: number;
  discount: number;
  finalTransactionPrice: number;
  discountText: string;
  transactionStatus: string;
  createdAt?: string | Date;
  updatedAt?: string | Date;
  items: [
    {
      transactionId: string;
      productId: string;
      productMetadata: {
        id: string;
        name: string;
        sku: string;
        brand: string;
        price: number;
        category: string;
        merchant_name: string;
        weight: number;
        images: string[];
      };
      price: number;
      qty: number;
      discount: number;
      discountText: string;
      finalTotalPrice: number;
      createdAt?: Date | string;
      updatedAt?: Date | string;
    },
  ];
  shipping: {
    transactionId: string;

    expeditionCode: string;
    expeditionService: string;
    expeditionEtd: string;
    expeditionMetadata: {
      expeditionName: string;
      serviceName: string;

      addressId?: string;
      addressName: string;
      receiverName: string;
      receiverPhone: string;
      fullAddress: string;
    };

    fromProvinceId: string;
    fromCityId: string;
    fromSubdistrictId: string;
    fromAddress: string;
    toProvinceId: string;
    toCityId: string;
    toSubdistrictId: string;
    toAddress: string;
    waybill: string | null;
    weight: number;
    shippingCost: number;
    shippingDiscount: number;
    createdAt?: Date | string;
    updatedAt?: Date | string;
  };
  invoice: Invoice;
};

export type Invoice = {
  invoiceId: string;
  externalId: string;
  description?: string | null;
  vendorInvoiceId?: string | null;
  amount: number;
  invoiceStatus: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  payment: {
    paymentId: string;
    invoiceId: string;
    paymentGateway: string;
    paymentMethod: string;
    paymentVendor: string;
    paymentRequestMetadata: {
      item_details: [
        {
          id: string;
          name: string;
          brand: string;
          price: number;
          category: string;
          quantity: number;
          merchant_name: string;
        },
      ];
      customer_details: {
        billing_address: any;
        shipping_address: any;
      };
      transaction_details: {
        order_id: string;
        gross_amount: number;
      };
    };
    paymentAccount: {
      type: string;
      bank: string;
      bill_key: string;
      biller_code: string;
    };
    amount: number;
    vendorFee: number;
    paymentStatus: string;
    paidAt: string | null;
    createdAt?: Date | string;
    updatedAt?: Date | string;
  };
};

export type CreateTransactionDto = {
  items: Array<{
    productId: string;
    productMetadata: {
      id: string;
      price: number;
      name: string;
      brand: string;
      category: string;
      merchant_name: string;
    };
    price: number;
    qty: number;
    totalPrice: number;
    discount: number;
    discountText: string;
  }>;

  discount: number;
  discountText: string;

  shipping: {
    expeditionCode: string;
    expeditionService: string;
    expeditionEtd: string;
    expeditionMetadata: {
      expeditionName: string;
      serviceName: string;

      addressId?: string;
      addressName: string;
      receiverName: string;
      receiverPhone: string;
      fullAddress: string;
    };

    to: {
      provinceId: string;
      cityId: string;
      subdistrictId: string;
      address: string;
    };
    weight: number;
    cost: number;
    discount: number;
  };

  paymentMethod: string;
  paymentVendor: string;

  customerDetail: any;
  billingAddress: any;
  shippingAddress: any;
};

export type CreateTransactionResponse = {
  transaction: Transaction;
  invoice: Invoice;
};

export type FetchListTransactionParams = {
  page: number;
  perPage: number;
  search?: string;
  filterStatus: string[];
};

export type FetchListTransactionInvoiceResponsePayload = {
  data: Array<Transaction>;
  page: number;
  perPage: number;
  totalPage: number;
  totalData: number;
};

export type GetDetailResponse = {
  transaction: Transaction;
  invoice: Invoice;
};

export async function apiGetDetailTransactionInvoiceByTransaction(transactionId: string, headers: any): Promise<GetDetailResponse> {
  const res: {
    data: GeneralResponse & {
      payload: GetDetailResponse;
    };
  } = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_BASE_URL}/transaction-invoice/${transactionId}/by-transaction`,
    headers,
  });

  return res.data.payload;
}

export async function apiGetDetailTransactionInvoiceByInvoice(invoiceId: string, headers: any): Promise<GetDetailResponse> {
  const res: {
    data: GeneralResponse & {
      payload: GetDetailResponse;
    };
  } = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_BASE_URL}/transaction-invoice/${invoiceId}/by-invoice`,
    headers,
  });

  return res.data.payload;
}
