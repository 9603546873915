export interface FilterState {
  all: boolean;
  house: boolean;
  cooking: boolean;
  horeca: boolean;
  apartment: boolean;
  minPrice: number | string | null;
  maxPrice: number | string | null;
  popularity: boolean;
  mostExpensive: boolean;
  cheapest: boolean;
  newest: boolean;
  oldest: boolean;
}

export const initialFilterState: FilterState = {
  all: false,
  house: false,
  cooking: false,
  horeca: false,
  apartment: false,
  minPrice: null,
  maxPrice: null,
  popularity: false,
  mostExpensive: false,
  cheapest: false,
  newest: false,
  oldest: false,
};
