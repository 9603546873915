import * as React from 'react';
import { makeStyles, createStyles, Theme, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { CheckCircle } from 'react-feather';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      borderRadius: 10,
      padding: theme.spacing(3),
      textAlign: 'center',
      '& .MuiPaper-root': {
        borderRadius: 10,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
        padding: theme.spacing(3),
      },
      '& .MuiButton-root': {
        minWidth: '100%',
        borderRadius: 10,
        height: 45,
        textTransform: 'capitalize',
      },
    },
    dialogTitle: {
      fontSize: 20,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
      fontWeight: 700,
    },
    dialogContent: {
      fontSize: 14,
    },
    iconSuccess: {
      borderRadius: 100,
      border: `5px solid ${grey[200]}`,
      padding: theme.spacing(2),
    },
  }),
);

interface DialogOrder {
  open: boolean;
  handleClose: () => void;
}

const DialogOrder: React.FC<DialogOrder> = (props: DialogOrder) => {
  const classes = useStyle();

  return (
    <Dialog
      maxWidth="sm"
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <CheckCircle size={64} className={classes.iconSuccess} />
        <Typography variant="h6" className={classes.dialogTitle}>
          Order Created
        </Typography>
        <Typography variant="body1" className={classes.dialogContent}>
          Congratulations, your order has been successfully made. Please wait for approval from our sales team.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.handleClose} color="primary">
          See Order List
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogOrder;
