import {
  Card,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import * as React from 'react';
import { FilterState, initialFilterState } from '../../interface/FilterInterface';
import NumberFormat from 'react-number-format';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '18%',
      '& .MuiDivider-root': {
        margin: `${theme.spacing(2)}px 0`,
      },
    },
    filterCard: {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
      border: '1px solid #EAEBEC',
      borderRadius: 10,
    },
    sectionTitle: {
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    categoryList: {
      '& > *': {
        display: 'block',
      },
      '& .MuiTypography-root': {
        color: grey[600],
        fontSize: 13,
      },
      '& .MuiIconButton-root': {
        padding: 5,
        paddingLeft: theme.spacing(1),
      },
    },
    filterTitle: {
      marginBottom: theme.spacing(1),
    },
    priceList: {
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: theme.spacing(1),
      },
      '& .MuiTypography-root, input': {
        fontSize: 13,
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 10,
      },
    },
    inputEmpty: {
      '& .MuiInputBase-root': {
        backgroundColor: grey[100],
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${grey[100]}`,
      },
      '& .Mui-focused': {
        backgroundColor: '#fff !important',
      },
    },
    inputFilled: {
      '& .MuiInputBase-root': {
        backgroundColor: '#fff',
      },
      '& .Mui-focused': {
        backgroundColor: '#fff !important',
      },
    },
  }),
);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

const Filter = () => {
  const classes = useStyle();
  const [filter, setFilter] = React.useState<FilterState>(initialFilterState);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilter({ ...filter, [event.target.name]: event.target.checked || event.target.value });
  }

  return (
    <Box className={classes.root}>
      <Box position="sticky" top={90}>
        <Typography variant="body1" component="p" className={classes.sectionTitle}>
          Filter
        </Typography>
        <Card className={classes.filterCard}>
          <CardContent>
            <Typography className={classes.filterTitle} variant="body2" component="p">
              Category
            </Typography>
            <Box className={classes.categoryList}>
              <FormControlLabel
                control={<Checkbox checked={filter.all} onChange={handleChange} name="all" color="primary" />}
                label="All"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.house} onChange={handleChange} name="house" color="primary" />}
                label="House"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.cooking} onChange={handleChange} name="cooking" color="primary" />}
                label="Cooking"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.horeca} onChange={handleChange} name="horeca" color="primary" />}
                label="Horeca"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.apartment} onChange={handleChange} name="apartment" color="primary" />}
                label="Apartment"
              />
            </Box>
            <Divider />
            <Typography className={classes.filterTitle} variant="body2" component="p">
              Price Range
            </Typography>
            <Box className={classes.priceList}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Min price"
                value={filter.minPrice}
                onChange={handleChange}
                className={filter.minPrice ? classes.inputFilled : classes.inputEmpty}
                name="minPrice"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                }}
              />
              <TextField
                variant="outlined"
                size="small"
                placeholder="Max price"
                value={filter.maxPrice}
                onChange={handleChange}
                className={filter.maxPrice ? classes.inputFilled : classes.inputEmpty}
                name="maxPrice"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
                }}
              />
            </Box>
            <Divider />
            <Typography className={classes.filterTitle} variant="body2" component="p">
              Sort By
            </Typography>
            <Box className={classes.categoryList}>
              <FormControlLabel
                control={<Checkbox checked={filter.popularity} onChange={handleChange} name="popularity" color="primary" />}
                label="Popularity"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.mostExpensive} onChange={handleChange} name="mostExpensive" color="primary" />}
                label="Most Expensive"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.cheapest} onChange={handleChange} name="cheapest" color="primary" />}
                label="Cheapest"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.newest} onChange={handleChange} name="newest" color="primary" />}
                label="Newest"
              />
              <FormControlLabel
                control={<Checkbox checked={filter.oldest} onChange={handleChange} name="oldest" color="primary" />}
                label="Oldest"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Filter;
