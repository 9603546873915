import React from 'react';
import { Box } from '@material-ui/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useScript from '../hooks/useScript';
import { parseURLSearch } from '../utils/url';

export default function PaymentCreditCardAuthenticate() {
  const location: any = useLocation();
  console.info('location.search', location.search);
  const search = parseURLSearch(location.search);
  const redirect_url = search.redirect_url;

  //change this to the script source you want to load, for example this is snap.js sandbox env
  const midtransScriptUrl = 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js';
  //change this according to your client-key
  const myMidtransClientKey = 'SB-Mid-client-jbX8-F3j1NeHCEpu';
  const environment = 'sandbox';
  useScript(midtransScriptUrl, [
    {
      key: 'id',
      value: 'midtrans-script',
    },
    {
      key: 'data-environment',
      value: environment,
    },
    {
      key: 'data-client-key',
      value: myMidtransClientKey,
    },
  ]);
  useScript('https://cdnjs.cloudflare.com/ajax/libs/picomodal/3.0.0/picoModal.js', []);

  // callback functions
  const options = {
    performAuthentication: function (redirect_url: any) {
      // Implement how you will open iframe to display 3ds authentication redirect_url to customer
      popupModal.openPopup(redirect_url);
    },
    onSuccess: function (response: any) {
      // 3ds authentication success, implement payment success scenario
      console.log('success response:', response);
      popupModal.closePopup();
      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ ...response, response_status: 'success' }));
      }
    },
    onFailure: function (response: any) {
      // 3ds authentication failure, implement payment failure scenario
      console.log('response:', response);
      popupModal.closePopup();
      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ ...response, response_status: 'error' }));
      }
    },
    onPending: function (response: any) {
      // transaction is pending, transaction result will be notified later via
      // HTTP POST notification, implement as you wish here
      console.log('response:', response);
      popupModal.closePopup();
      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({ ...response, response_status: 'pending' }));
      }
    },
  };

  const popupModal = (function () {
    let modal: any = null;
    return {
      openPopup(url: string) {
        modal = (window as any)
          .picoModal({
            content: '<iframe frameborder="0" style="height:100vh; width:100%;" src="' + url + '"></iframe>',
            width: '100%',
            closeButton: false,
            overlayClose: false,
            escCloses: false,
          })
          .show();
      },
      closePopup() {
        try {
          modal.close();
        } catch (e) {}
      },
    };
  })();

  useEffect(() => {
    setTimeout(() => {
      (window as any).MidtransNew3ds.authenticate(redirect_url, options);
    }, 1000);
  }, []);

  // trigger `getCardToken` function

  return <Box style={{ padding: 10 }} />;
}
