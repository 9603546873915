import * as React from 'react';
import { useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
import history from './history';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from './store';
import About from './pages/About';
import Catalog from './pages/Catalog';
import Landing from './pages/Landing';
import LogIn from './pages/LogIn';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import { checkLogin } from './store/auth/actions';
import NavBar from './components/Navbar/NavBar';
import Grid from '@material-ui/core/Grid';
import CatalogDetail from './pages/CatalogDetail';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Cart from './pages/Cart';
import OrderList from './pages/Order';
import Profile from './pages/Profile';
import OrderDetail from './pages/OrderDetail';
import InvoiceDetail from './pages/InvoiceDetail';
import TermAndCondition from './pages/TermAndCondition';
import { theme } from './styles/theme';
import { ThemeProvider } from '@material-ui/core';
import PaymentCreditCard from './pages/PaymentCreditCard';
import PaymentCreditCardAuthenticate from './pages/PaymentCreditCardAuthenticate';
interface StateProps {
  isAuthenticated: boolean | null;
}

interface DispatchProps {
  checkLogin: () => void;
}

type Props = StateProps & DispatchProps;

const App: React.FC<Props> = ({ checkLogin, isAuthenticated }: Props) => {
  useEffect(() => {
    console.info(isAuthenticated);
    checkLogin();
  }, []);

  const app =
    isAuthenticated !== null ? (
      <Router history={history}>
        {/* {isAuthenticated && <NavBar />} */}
        {window.location.pathname.includes('/invoice/') ||
        window.location.pathname.includes('/term-and-condition') ||
        window.location.pathname.includes('/payment-cc') ? (
          false
        ) : (
          <NavBar />
        )}
        <Grid container>
          <Switch>
            <PublicRoute restricted={false} path="/" exact={true} component={Landing} />
            <PublicRoute restricted={true} path="/log-in" exact={true} component={LogIn} />
            <PublicRoute restricted={false} path="/forgot-password" exact={true} component={ForgotPassword} />
            <PublicRoute restricted={false} path="/reset-password" exact={true} component={ResetPassword} />
            <PublicRoute restricted={false} path="/catalog" exact={true} component={Catalog} />
            <PublicRoute restricted={false} path="/catalog/:title" exact={true} component={CatalogDetail} />
            <PublicRoute restricted={false} path="/cart" exact={true} component={Cart} />
            <PublicRoute restricted={false} path="/order-list" exact={true} component={OrderList} />
            <PublicRoute restricted={false} path="/order-detail/:order_id" exact={true} component={OrderDetail} />
            <PublicRoute restricted={false} path="/profile" exact={true} component={Profile} />
            <PublicRoute restricted={false} path="/invoice/:invoiceId" exact={true} component={InvoiceDetail} />
            <PrivateRoute path="/about" exact={true} component={About} />
            <PublicRoute restricted={false} path="/term-and-condition" exact={true} component={TermAndCondition} />
            <PublicRoute restricted={false} path="/payment-cc" exact={true} component={PaymentCreditCard} />
            <PublicRoute restricted={false} path="/payment-cc-authenticate" exact={true} component={PaymentCreditCardAuthenticate} />
          </Switch>
        </Grid>
      </Router>
    ) : null;

  return <ThemeProvider theme={theme}>{app}</ThemeProvider>;
};

const mapStateToProps = (state: ApplicationState) => ({ isAuthenticated: state.auth.isAuthenticated });

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    checkLogin: () => dispatch(checkLogin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
