import * as React from 'react';

import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ApplicationState } from '../store';

interface StateProps {
  exact?: boolean;
  isAuthenticated: boolean | null;
  path: string;
  restricted: boolean;
  component: React.FC;
}

const PublicRoute: React.FC<StateProps> = ({ isAuthenticated, restricted, ...otherProps }: StateProps) => {
  if (isAuthenticated && restricted) {
    return <Redirect to="/home" />;
  }
  console.info('public route', isAuthenticated, restricted);
  return <Route {...otherProps} />;
};

const mapStateToProps = (state: ApplicationState) => ({ isAuthenticated: state.auth.isAuthenticated });

export default connect(mapStateToProps)(PublicRoute);
