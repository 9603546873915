import * as React from 'react';
import { makeStyles, Theme, createStyles, Checkbox, FormControlLabel, Button, Box } from '@material-ui/core';
import { red, grey } from '@material-ui/core/colors';
import CartItem from '../components/Cart/CartItem';
import { Trash2 } from 'react-feather';
import DialogOrder from '../components/Cart/DialogOrder';
import DialogLocation from '../components/Cart/DialogLocation';
import OrderSummary from '../components/Cart/OrderSummary';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    root: {
      margin: '0 auto',
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 980,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
      marginBottom: theme.spacing(16),
    },
    headerCart: {
      display: 'grid',
      height: 60,
      borderBottom: `4px solid ${grey[200]}`,
      alignItems: 'center',
      gridTemplateColumns: 'minmax(150px, 1fr) auto',
      columnGap: 20,
      paddingLeft: 27,
      paddingRight: theme.spacing(2),
    },
    btnRemove: {
      color: red[500],
      '& .MuiButton-label': {
        textTransform: 'capitalize',
      },
    },
    cartWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 25%',
      columnGap: 20,
    },
  }),
);

const Cart = () => {
  const classes = useStyle();
  const [chooseAll, setChooseAll] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState(false);

  function handleChooseAll() {
    setChooseAll(!chooseAll);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        {/* <BreadCrumb title={'Cart Detail'} handleClick={handleClick} /> */}

        <Box className={classes.cartWrapper}>
          <Box>
            <Box className={classes.headerCart}>
              <FormControlLabel
                control={<Checkbox checked={chooseAll} onChange={handleChooseAll} name="mostExpensive" color="primary" />}
                label="Choose All"
              />
              <Button variant="text" className={classes.btnRemove}>
                <Trash2 size={20} />
                <Box component="span" ml={1}>
                  Remove all
                </Box>
              </Button>
            </Box>

            {Array.from(Array(20).keys()).map((value) => (
              <CartItem index={value} key={value} />
            ))}
          </Box>
          <OrderSummary
            totalQty={20}
            totalPrice={45000000}
            handleLocationClick={() => setLocation(true)}
            handleOrderClick={() => setOpen(true)}
          />
        </Box>

        <DialogOrder open={open} handleClose={() => setOpen(false)} />
        <DialogLocation open={location} handleClose={() => setLocation(false)} />
      </Box>
    </Box>
  );
};

export default Cart;
