import * as React from 'react';
import { makeStyles, createStyles, Theme, Typography, Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ImageProduct from '../components/Product/ImageProduct';
import AddProduct from '../components/Product/AddProduct';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import { grey } from '@material-ui/core/colors';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    root: {
      margin: '0 auto',
      padding: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: 980,
      },
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
      },
    },
    content: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      display: 'grid',
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'minmax(0px, 348px) minmax(0px, 1fr) minmax(0px, 228px)',
        columnGap: 52,
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'minmax(0px, 300px) minmax(0px, 1fr) minmax(0px, 200px)',
        columnGap: 32,
      },
    },
    category: {
      color: grey[400],
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    price: {
      marginBottom: theme.spacing(4),
      fontWeight: 700,
    },
    titleSection: {
      fontWeight: 700,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1),
    },
    textGroup: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) minmax(0px, 1fr)',
      columnGap: 52,
    },
    label: {
      color: grey[400],
      marginBottom: theme.spacing(1),
    },
    labelText: {
      marginBottom: theme.spacing(1),
    },
    productDetail: {
      paddingInlineStart: 20,
      '& li': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${grey[200]}`,
      },
      '& li:first-child': {
        paddingTop: theme.spacing(0),
      },
      '& li:last-child': {
        paddingBottom: theme.spacing(0),
        borderBottom: 'none',
      },
    },
  }),
);

const CatalogDetail = () => {
  const classes = useStyle();
  const history = useHistory();
  const { title }: any = useParams<any>();

  React.useEffect(() => {
    console.info(title);
  }, []);

  function handleClick() {
    history.goBack();
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <BreadCrumb title={title} handleClick={handleClick} />

        <Box className={classes.content}>
          <ImageProduct />
          <Box>
            <Typography variant="body1" component="p" className={classes.category}>
              Cooking
            </Typography>
            <Typography variant="h5" component="h3" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="h5" component="h3" className={classes.price}>
              Rp 450.000
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.titleSection}>
              Specification
            </Typography>
            <Box className={classes.textGroup}>
              <Box>
                <Typography variant="body2" component="p" className={classes.label}>
                  Capacity
                </Typography>
                <Typography variant="body1" component="p" className={classes.labelText}>
                  20L
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" component="p" className={classes.label}>
                  Power Supply
                </Typography>
                <Typography variant="body1" component="p" className={classes.labelText}>
                  1500Watt
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" component="p" className={classes.label}>
                  Voltage
                </Typography>
                <Typography variant="body1" component="p" className={classes.labelText}>
                  220Hz - 50Hz
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1" component="p" className={classes.titleSection}>
              Product Detail
            </Typography>
            <ul className={classes.productDetail}>
              <li>
                <Typography variant="body1" component="p">
                  Glass Turnable
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="p">
                  Auto Cooking Menu
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="p">
                  Bake, Grill, Reheat, Defrost
                </Typography>
              </li>
            </ul>
            <Typography variant="subtitle1" component="p" className={classes.titleSection}>
              Description
            </Typography>
            <Typography variant="body1" component="p">
              OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita dapat
              menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak. OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita
              dapat menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak.
            </Typography>
            <Typography variant="body1" component="p">
              OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita dapat
              menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak. OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita
              dapat menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak.
            </Typography>
            <Typography variant="body1" component="p">
              OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita dapat
              menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak. OXONE Professional Air Fryer OX-199 merupakan alat dengan teknologi terbaru dalam memasak. Dengan alat ini kita
              dapat menggoreng tanpa menggunakan minyak, itu membuat makanan kita menjadi lebih sehat untuk kita konsumsi. Karena alat ini
              mempunyai fitur Air Fryer. Dengan alat ini makanan juga dapat kita masak dengan tingkat kematangan yang sama. TAHAP 1 :
              Pemanasan perangkat pada bagian atas produk air panas dengan pesat sangat panas TAHAP 2 : Melaui kipas kuat, mereka air suhu
              tinggi cepatnya akan beredar di dalam appliance atas TAHAP 3 : Desain khusus unutk kotribusi aliran udara dengan permukaan
              makanan untuk cepat diambil jauh uap dan menciptakan permukaan garing kecoklatan, kemudian akan muncul rasa seperti goreng
              dengan minyak.
            </Typography>
          </Box>
          <AddProduct />
        </Box>
      </Box>
    </Box>
  );
};

export default CatalogDetail;
